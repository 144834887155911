<template>
    <ElementGroup>
        <InputWrapper
            class="multiselect-wrapper"
            :class="isOpen ? 'multiselect-open' : null"
            :label="label + (max ? ' (Max. ' + max + ')' : '')"
            :label-for="id"
            :helpText="helpText"
            :focused="isOpen"
            :disabled="disabled"
            :required="required"
            :errors="errors"
            :resettable="isDirty"
            @reset="reset"
            @focus="focus"
            v-click-outside="() => {isOpen = false}"
        >
            <template #status-bar-additional><slot name="status-bar-additional" /></template>
            <Multiselect
                :id="id"
                :name="name"
                :label="labelBy"
                :track-by="trackBy"
                :class="[isOpen ? 'open' : null, multiple ? 'multiple-select' : 'single-select']"
                class="select-input"
                :multiple="multiple"
                :options="options||[]"
                :allow-empty="!required && allowEmpty"
                :preselect-first="!allowEmpty && !allowInitEmpty"
                :hide-selected="multiple"
                :placeholder="visiblePlaceholder"
                :disabled="disabled"
                :max-height="maxMultiselectHeight"
                v-model="selected"
                :close-on-select="!multiple"
                :taggable="taggable"
                :group-label="groupLabel"
                :group-values="groupValues"
                :group-select="groupSelect"
                :max="max"
                :tagPlaceholder="null"
                tag-position="bottom"
                @tag="onTag"
                @update:modelValue="onInput"
                @search-change="qt => searchText = qt"
                @open="isOpen = true"
                @close="isOpen = false"
                ref="input"
            >
                <template #singleLabel="{ option }">
                    <span
                        :class="option.color ? 'option-with-color' : null"
                        :style="{backgroundColor: option.color ? option.color : null, color: getTextColor(option.color)}"
                    >
                        {{ option[labelBy] }}
                    </span>
                </template>
                <template #tag="{ option, remove }">
                    <template v-if="sortable">
                        <span
                            class="item"
                            :data-value="option[trackBy]"
                            @mousedown="mousedown"
                            @mouseup="mouseup"
                            @dragstart="dragstart($event, option)"
                            @drop="drop($event, option)"
                            @dragover.prevent
                            @dragenter="dragenter"
                            @dragleave="dragleave"
                            draggable="true"
                        >
                            <span>{{ option[labelBy] }}</span>
                            <span class="btn-item-remove" @click.stop="remove(option)">
                                <IconLight icon="times"/>
                            </span>
                        </span>
                    </template>
                    <template v-else>
                        <span
                            :class="option.color ? 'option-with-color' : null"
                            :style="{backgroundColor: option.color ? option.color : null, color: getTextColor(option.color)}"
                            class="item"
                            :data-value="option[trackBy]"
                        >
                            <span>{{ option[labelBy] }}</span>
                            <span class="btn-item-remove" @click.stop="remove(option)">
                                <IconLight icon="times" />
                            </span>
                        </span>
                    </template>
                </template>
                <template #option="{ option }">
                    <span v-if="option.$isLabel">{{ option.$groupLabel }}</span>
                    <span v-else :class="option[descriptionBy] ? 'multiselect__flex' : ''"
                    >
                        <div class="multiselect__flex-data">
                            <span
                                class="text-overflow-element selected-title"
                                :class="option.color ? 'option-with-color' : null"
                                :style="{backgroundColor: option.color ? option.color : null, color: getTextColor(option.color)}"
                            >
                                {{ option.isTag ? $root.l10n('no_items_to_select_but_addable') : option[labelBy] }}
                            </span>
                            <span
                                v-if="option[descriptionBy]"
                                class="text-overflow-element selected-sub-title"
                            >
                                {{ option[descriptionBy] }}
                            </span>
                            <IconLight icon="times" v-if="!required && allowEmpty"/>
                        </div>
                    </span>
                </template>
                <template #noOptions>
                    <span class="text-alert">{{$root.l10n('no_items_to_select')}}</span>
                </template>
                <template #noResult>
                    <span class="text-alert">{{$root.l10n('no_items_to_select')}}</span>
                </template>
                <template #clear>
                    <div class="additional">
                        <div class="multiselect__icon" @mousedown.prevent.stop="toggle">
                        <span class="multiselect__icon__toggle">
                            <IconSolid icon="caret-down" class="multiselect__icon__arrow"/>
                        </span>
                        </div>
                        <button v-if="!required && allowEmpty && !disabled && Object.keys(selected).length > 0 || showClearBtn" type="button" @click.stop="cleanSelection" tabindex="-1">
                            <IconLight icon="times" />
                        </button>
                        <slot />
                    </div>
                </template>
            </Multiselect>

        </InputWrapper>
        <template #additional><slot name="info" /></template>
    </ElementGroup>
</template>

<style lang="scss">
.text-overflow-element.option-with-color {
    border-radius: 2px;
    padding-left: 4px;
    padding-right: 4px;
    margin-left: -4px;
}
</style>

<script>
import ElementGroup from '../ElementGroup'
import InputWrapper from '../InputWrapper'
import IconLight from '../../utility/IconLight'
import IconSolid from '../../utility/IconSolid'
import Multiselect from 'vue-multiselect'
import DefaultValue from '../../../lib/mixins/defaultValue'
import {directive as ClickOutside} from 'click-outside-vue3'
import {getTextColor} from '@/lib/utility'

import './multiselect.scss'

export default {
    name: "SelectInput",
    components: {
        Multiselect,
        ElementGroup,
        InputWrapper,
        IconLight,
        IconSolid
    },
    directives: {
        ClickOutside
    },
    mixins: [DefaultValue],
    props: {
        id: [Number, String],
        label: String,
        name: String,
        modelValue: [String,Number,Array],
        options: Array,
        trackBy: {
            type: String,
            default: 'value'
        },
        labelBy: {
            type: String,
            default: 'title'
        },
        descriptionBy: {
            type: String,
            default: 'description'
        },
        placeholder: String,
        required: Boolean,
        disabled: Boolean,
        multiple: {
            type: Boolean,
            default: false
        },
        sortable: {
            type: Boolean,
            default: false
        },
        allowEmpty: {
            type: Boolean,
            default: true
        },
        allowInitEmpty: Boolean,
        errors: [Object,Array,String],
        helpText: String,
        taggable: Boolean,
        showClearBtn: Boolean,
        groupLabel: String,
        groupSelect: Boolean,
        groupValues: String,
        max: Number,
        maxMultiselectHeight: {
            type: Number,
            default: 205
        },
        disableList: Boolean,
    },
    data() {
        const selected = this.adoptValue(this.modelValue)
        //this.onInput(selected.length > 0 ? this.multiple ? selected : selected[0] : [])
        this.onInput(selected)

        return {
            isOpen: false,
            selected: selected,
            searchText: '',
            draggedEl: null,
            draggedVal: null
        }
    },
    computed: {
        /** @override DefaultValue */
        computedValue() {
            return this.selected
        },
        /**
         * @returns {?string}
         */
        visiblePlaceholder() {
            if (this.isOpen) {
                return null
            } else if (this.selected && this.selected.length > 0) {
                return this.multiple ? this.selected[0][this.labelBy] : this.selected[this.labelBy]
            } else return this.placeholder || this.$root.l10n('type_to_filter')
        }
    },
    methods: {
        getTextColor,
        /**
         * @param {Array|String} list
         * @param {String|Number} value
         * @returns {Boolean}
         */
        _indexOf(list, value) {
            return typeof list === 'string' ?
                list.indexOf(String(value)) !== -1 :
                list.find(v => String(v) === String(value))
        },
        /**
         * @param {any} value
         * @returns {array}
         */
        adoptValue(value) {
            let adopted
            if (value instanceof Array) adopted = value
            else if (value != null && this.multiple) adopted = value.split(',')
            else if (value != null) adopted = [value]
            else adopted = []

            let options = this.options ? this.groupValues ? [].concat.apply([], this.options.map(o => o[this.groupValues])) : this.options : []
            let optionsCopy = []
            for (let adoptedVal of adopted) {
                let opt = options.find(o => String(adoptedVal) === String(o[this.trackBy]))
                if (opt && !optionsCopy.includes(opt)) optionsCopy.push(opt)
            }

            if (optionsCopy.length === 0 && (this.required || !this.allowEmpty) && this.options?.length > 0)
                if ((this.defaultValue && String(this.defaultValue) !== '') && !this.allowInitEmpty)
                    optionsCopy = [this.options[0]]

            return this.multiple ? optionsCopy : optionsCopy[0] || ''
        },
        onInput(options) {
            let value
            if (this.multiple) {
                value = options ? options.map(o => o[this.trackBy]) : []
            } else if (options && !(options instanceof Array)) value = options[this.trackBy]
            else value = ''
            if (value !== this.modelValue) {
                this.$emit('update:modelValue', value)
            }
        },
        cleanSelection() {
            this.$emit('update:modelValue', this.multiple ? [] : '')
            this.$emit('clear')
        },
        onTag(tag) {
            this.$emit('tag', tag)
        },
        focus() {
            this.isOpen = true
            this.$refs.input && this.$refs.input.$el.focus()
        },
        /**
         * @override DefaultValue
         */
        reset() {
            this.onInput(this.defaultValue ? this.multiple ? this.defaultValue : this.defaultValue[0] : [])
        },
        mousedown() {
            // workaround focus input chrome
            if (this.$refs.input)
                this.$refs.input.$el.querySelector('.multiselect__input').disabled = true
        },
        mouseup() {
            // workaround focus input chrome
            if (this.$refs.input)
                this.$refs.input.$el.querySelector('.multiselect__input').disabled = false
        },
        dragstart(evt, option) {
            this.$refs.input.$el.classList.add('dragging')
            this.draggedEl = evt.target
            this.draggedVal = option.value
        },
        dragenter(evt) {
            if (evt.target !== this.draggedEl) {
                evt.target.classList.add('hover')
            }
        },
        dragleave(evt) {
            evt.target.classList.remove('hover')
        },
        drop(evt, option) {
            if (this.draggedEl !== evt.target) {
                evt.target.classList.add('animate-slide-left-to-right')
                let currentIndex = this.selected.findIndex(o => o.value === this.draggedVal)
                let newIndex = this.selected.findIndex(o => o.value === option.value)

                this.selected.splice(newIndex, 0, this.selected.splice(currentIndex, 1)[0])
                this.onInput(this.selected)

                evt.target.classList.remove('hover')
                this.$refs.input.$el.classList.remove('dragging')

                setTimeout(() => {
                    evt.target.classList.remove('animate-slide-left-to-right')
                }, 150)
            }
            // workaround focus input chrome
            document.querySelector('.multiselect__input').disabled = false
            return false
        },
        toggle() {
            this.isOpen ? this.$refs.input.deactivate() : this.$refs.input.activate()
        }
    },
    watch: {
        modelValue(to) {
            this.selected = this.adoptValue(to)
        },
        options () {
            this.selected = this.adoptValue(this.modelValue)
            this.onInput(this.selected)
        },
        multiple() {
            this.selected = this.adoptValue(this.modelValue)
            this.onInput(this.modelValue)
        }
    },
    mounted() {
        this.$refs.input?.$el.querySelector('input[type=text]').setAttribute('type', 'search')
    }
}
</script>