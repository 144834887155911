<template>
    <ElementGroup :info="readonly">
        <slot name="before-input" />
        <InputWrapper
            class="multiselect-wrapper"
            :class="isOpen ? 'multiselect-open' : null"
            v-if="!readonly || !listShown"
            :label="label"
            :label-for="id"
            :helpText="helpText"
            :focused="isOpen"
            :disabled="disabled || selected.length >= maxSelected"
            :required="required"
            :errors="errors"
            :resettable="isDirty"
            @reset="reset"
            @focus="focus"
            ref="inputWrapper"
        >
            <TextOutput v-if="readonly"
                :name="name"
                :value="modelValue"
                :options="selected"
            />
            <Multiselect v-else
                v-model="selected"
                class="autocomplete"
                label="title"
                track-by="value"
                :placeholder="(!isOpen && Object.keys(selected).length > 0) ? null : (placeholder || $root.l10n('type_to_search'))"
                :id="id"
                :name="name"
                :options="filteredOptions"
                :class="{open: isOpen, 'single-select': !multiple && inline}"
                :multiple="multiple"
                :loading="loading"
                :searchable="true"
                :internal-search="true"
                data-preserve-search="true"
                data-reset-after="true"
                :clear-on-select="multiple"
                :close-on-select="!multiple"
                :max-height="205"
                :hide-selected="true"
                :disabled="disabled"
                :customLabel="searchText"
                :blockKeys="['Delete']"
                @open="onOpen"
                @close="onClose"
                @update:modelValue="onInput"
                @search-change="(text) => asyncFind(text, preload)"
                ref="input"
            >
                <template #singleLabel="{option}">
                    <slot name="singleLabel" :option="option">
                        <template v-if="inline">{{option.title}}</template>
                        <template v-else>&nbsp;</template>
                    </slot>
                </template>
                <template #option="{option, search}">
                    <slot name="option" :option="option" :search="search">{{option.title}}</slot>
                </template>
                <template #tag="{option, remove}" >
                    <span v-if="inline" class="item" :data-value="option.value">
                        <span>{{ option.title }}</span>
                        <span class="btn-item-remove" @click.stop="remove(option)">
                            <IconLight icon="times" />
                        </span>
                    </span>
                    <span class="multiselect__placeholder" v-else-if="selected[0].value === option.value && !isOpen">{{placeholder || $root.l10n('type_to_search')}}</span>
                    <div v-else style="display:none;"></div>
                </template>
                <template #noResult>
                    <slot name="noResult">
                        <span class="text-alert">{{$root.l10n('no_items_to_select')}}</span>
                    </slot>
                </template>
                <template #noOptions>
                    <slot name="noResult">
                        <span class="text-alert">{{$root.l10n('no_items_to_select')}}</span>
                    </slot>
                </template>
                <template #clear>
                    <slot name="clear">
                        <div class="additional">
                            <div v-if="loading" class="multiselect__icon" @mousedown.prevent.stop="toggle">
                                <LoadingSpinner class="spinner-center-vertical"/>
                            </div>
                            <div v-if="previewMode === 'user'" class="multiselect__icon" @mousedown.prevent.stop="toggle">
                                <span class="multiselect__icon__toggle">
                                    <IconSolid icon="caret-down" class="multiselect__icon__arrow"/>
                                </span>
                            </div>
                            <button type="button" v-if="!disabled && selected && (selected.length > 0 || Object.keys(selected).length > 0)" @click.stop="cleanSelection" tabindex="-1">
                                <IconLight icon="times" />
                            </button>
                            <slot />
                        </div>
                    </slot>
                </template>
            </Multiselect>
        </InputWrapper>
        <InputWrapper v-if="listShown"
            :input-list="true"
            :label="readonly ? label : listLabel"
            :disabled="disabled"
        >
            <slot name="list" :list="selected">
            <div class="table-container table-simple table-no-header">
                <div v-for="el in selected" class="flex-table-row" :key="el.value" :data-id="$root.debug ? el.value : null"
                    v-draggable="{draggable: sortable, handle: '.flex-cell'}"
                    @dragstart="dragging = el"
                    @dropped="() => onElementDrop(el)">
                    <div v-if="sortable" class="icon-grip">
                        <IconRegular icon="grip-dots-vertical"></IconRegular>
                    </div>
                    <slot name="preview" :el="el">
                        <div v-if="preview" class="static-cell preview-cell">
                            <ItemPreview :path="imageBasePath" :file="el.imagePath" :icon="icon" :previewMode="previewMode"/>
                        </div>
                    </slot>
                    <slot name="previewText" :el="el">
                        <div class="flex-cells-wrapper">
                            <div role="cell" class="flex-cell">
                                <div class="media-item-attribute ">
                                    <TextOverflow class="selected-title">{{ el.title }}</TextOverflow>
                                    <TextOverflow v-if="el.subTitle" class="selected-sub-title" v-html="el.subTitle" />
                                </div>
                            </div>
                        </div>
                    </slot>
                    <div class="static-cell action-cell" :class="actions && actions.length < 4 ? 'dont-hide-buttons' : null" role="cell">
                        <slot name="actions" :handler="handleButton" :option="el">
                            <template v-for="action in actions" :key="action.id">
                                <button type="button" v-if="action.toggle"
                                    class="btn-std btn-secondary btn-icon-only"
                                    :class="el[action.id] ? 'active' : null"
                                    @click.prevent="handleButton(action, el, $event)"
                                    v-tooltip="action.title || $root.l10n(action.id)"
                                >
                                    <IconLight :icon="action.icon"/>
                                </button>
                                <button type="button" v-else
                                    class="btn-std btn-secondary btn-icon-only"
                                    @click.prevent="handleButton(action, el, $event)"
                                    v-tooltip="action.title || $root.l10n(action.id)"
                                >
                                    <IconLight :icon="action.icon"/>
                                </button>
                            </template>
                        </slot>
                    </div>
                    <slot name="after-each-selected" :option="el"/>
                </div>
            </div>
            </slot>
        </InputWrapper>
        <template #additional><slot name="info" /></template>
    </ElementGroup>
</template>

<script>
import Multiselect from 'vue-multiselect'
import TextOutput from './TextOutput'
import ElementGroup from '../ElementGroup'
import InputWrapper from '../InputWrapper'
import ajax from "../../../lib/ajax"
import LoadingSpinner from "../../layout/LoadingSpinner";
import IconLight from '../../utility/IconLight'
import IconSolid from '../../utility/IconSolid'
import ItemPreview from '../../layout/content/items/ItemPreview'
import TextOverflow from "../../utility/TextOverflow";
import DefaultValue from '../../../lib/mixins/defaultValue'
import './multiselect.scss'
import IconRegular from '../../utility/IconRegular.vue'
import draggable, {resortList} from '../../../lib/mixins/draggable'


/** to avoid same queries by different component instances */
const cacheLifeTime = 1e3
const cacheStorage = {}
/**
 * @param {string} url
 * @param {object} data
 * @return {Promise.<object>}
 * @see ajax.json
 */
const cachedAjax = function (url, data) {
    const key = url + JSON.stringify(data)
    //console.debug('cached ajax', key, key in cacheStorage)
    if (key in cacheStorage) return cacheStorage[key]
    else return cacheStorage[key] = ajax.json(url, data).then(data => {
        setTimeout(() => delete cacheStorage[key], cacheLifeTime)
        //console.debug('cached ajax request done', key, key in cacheStorage)
        return data
    })
}

export default {
    name: "AutoCompleteInput",
    components: {
        IconRegular,
        TextOverflow,
        TextOutput,
        Multiselect,
        ElementGroup,
        InputWrapper,
        LoadingSpinner,
        IconLight,
        ItemPreview,
        IconSolid
    },
    mixins: [DefaultValue],
    directives: {
        draggable
    },
    props: {
        modelValue: [Array, Object, String],
        label: String,
        id: [Number, String],
        name: String,
        inline: Boolean,
        multiple: Boolean,
        disabled: Boolean,
        required: Boolean,
        readonly: Boolean,
        placeholder: String,
        listLabel: String,
        errors: [Object,Array,String],
        helpText: String,
        requestURL: { type: String, required: true },
        requestFilter: Object,
        allowEmptyQuery: Boolean,
        excludedId: [Array, String, Number],
        preload: Boolean,
        limit: Number,
        parseOption: { type: Function, required: true },
        searchText: Function,
        actions: Array,
        preview: Boolean,
        previewMode: String,
        imageBasePath: String,
        icon: [Array, String],
        disableList: Boolean,
        sortable: Boolean,
        maxSelected: Number
    },
    data() {
        const selected = this.value2selected(this.modelValue)

        return{
            options: [].concat(selected),
            selected: selected,
            loading: false,
            isOpen: false,
            notEmpty: false,
            timer: 0,
            dragging: null
        }
    },
    computed: {
        /** @override DefaultValue */
        computedValue() {
            return this.selected
        },
        /**
         * @return {Boolean}
         * @override DefaultValue
         */
        isDirty () {
            if (!this.defaultResettable) return false
            if (this.selected?.length !== this.defaultValue?.length) return true
            for (let i = 0; i < this.selected?.length; i++) {
                if (this.selected[i].value !== this.defaultValue[i].value) return true
            }
            return false;
        },
        /** @returns {Boolean} */
        listShown() {
            return !this.inline && this.selected?.length > 0
        },
        /** @returns {Object[]} */
        filteredOptions () {
            let excluded = this.excludedId || []
            if (typeof excluded === 'string' && excluded) excluded = excluded.split(',').map(v => String(v).trim())
            else if (!isNaN(excluded) && excluded) excluded = ['' + excluded + '']

            return this.options.filter(o => excluded.indexOf(String(o.value)) === -1)
        },
    },
    methods: {
        /**
         * @param {Array|Object|String} value
         * @return {Array}
         */
        value2selected (value) {
            value = value ? Array.isArray(value) ? [].concat(value) : [value] : []
            return value.map(v => typeof v === 'object' ? v : this.options?.find(o => o.value === v)).filter(v => !!v)
        },
        /** */
        cleanSelection () {
            this.onInput(this.selected = [])
        },
        /** */
        clearOptions () {
            this.options = []
        },
        /** */
        reloadOptions () {
            this.asyncFind ('', true)
        },
        /**
         * @param {string} query
         * @param {boolean} force
         */
        asyncFind(query, force = false) {
            clearTimeout(this.timer)
            if (query || this.allowEmptyQuery || force) {
                const isOpen = this.isOpen
                this.loading = true
                this.timer = setTimeout(() => {
                    this.sendRequest(query).then(() => {
                        this.loading = false
                        this.isOpen = isOpen
                        this.notEmpty = this.options.length > 0
                    })
                }, 500);
            } else {
                this.notEmpty = this.loading = false
                //this.isOpen = false
                this.options = []
            }
        },
        /**
         * @param {string} query
         */
        sendRequest (query) {
            const data = {
                query : query,
                limit: this.limit
            }
            if (this.requestFilter) data.filter = this.requestFilter
            if (this.excludedId) data.excludedId = this.excludedId

            const selected = JSON.parse(JSON.stringify(this.selected))

            return cachedAjax(this.requestURL, {data}).then(({response}) => {
                this.options = response.map(this.parseOption)
                this.extendOptions(this.options, selected)
            }).catch(e => console.error(e))
        },
        /**
         * @param {string} value
         */
        initRequest (value) {
            const selected = this.selected
            this.selected = []
            return this.sendRequest('ID:' + value).then(() => {
                this.selected = this.options.length > 0 ? this.multiple ? this.options : [this.options[0]] : selected
                this.onInput(this.selected)
            })
        },
        /**
         * @param {array} options
         * @param {object[]} selected
         */
        extendOptions(options, selected) {
            if (selected.length) {
                options.unshift(
                    ...selected.filter(option => !options.find(o => o.value === option.value))
                )
            }
        },
        handleButton(action, el, event) {
            switch(action.id) {
                case 'info':
                    this.openInNewTab(action.type + '.info', el)
                    break
                case 'detach':
                case 'remove':
                    if (event) event.stopPropagation()
                    this.removeSelected(el)
                    break
                case 'mailTo':
                    window.location.href = `mailto:${el.object?.Email}`
                    break
                default: this.$emit(action.id, el)
            }
        },
        openInNewTab(routeName, el) {
            const routePath = this.$root.getRoutePath(routeName, el.value)
            window.open(routePath)
        },
        removeSelected(el) {
            this.selected.splice(this.selected.findIndex(val => val === el), 1)
            this.selected = [].concat(this.selected)
            this.onInput(this.selected)
        },
        onInput(options) {
            let value = options ? options instanceof Array ? options : [options] : []
            this.$emit('update:modelValue', this.multiple ? value : value[0] || null)
        },
        onOpen() {
            this.isOpen = true
            this.$emit('open')
        },
        onClose(){
            this.isOpen = false
        },
        focus(){
            this.$refs.input && this.$refs.input.$el.focus()
        },
        isInitRequestNeeded(value) {
            return value && (
                (Array.isArray(value) && value.some(v => typeof v !== 'object')) || typeof value !== 'object'
            )
        },
        toggle() {
            this.isOpen ? this.$refs.input.deactivate() : this.$refs.input.activate()
        },
        onElementDrop(item) {
            const elementList = [...this.selected]
            if (resortList(elementList, this.dragging, item))
                this.selected = elementList
        },
    },
    watch : {
        modelValue (to) {
            if (this.isInitRequestNeeded(to)) {
                this.initRequest(this.modelValue)
            } else this.selected = this.value2selected(to)
        },
        requestURL () {
            this.asyncFind('', this.preload)
        },
        requestFilter () {
            this.asyncFind('', this.preload)
        },
        preload (value) {
            this.asyncFind('', value)
        },
        /*isOpen(){
            console.log('isOpen', this.isOpen);
        }*/
    },
    created () {
        if (this.isInitRequestNeeded(this.modelValue)) {
            const dV = this.defaultValue
            this.initRequest(this.modelValue).then(() => {
                if (dV === undefined) this.resetDefaultValue()
            })
        } else this.asyncFind('', !this.readonly && !!this.preload)
    }
}
</script>

<style scoped>
.icon-grip {
    margin: auto;
}
</style>