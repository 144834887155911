<template>
    <form
        v-show="enabled"
        id="headerSearch"
        :class="{onfocus: focused, onchange: enabled && changed, onsearch: enabled && active, disabled: !enabled}"
        @submit.prevent="search"
        v-click-outside="() => {focused = false}"
    >
        <button type="button" id="search-filter" :class="{'search-terms-active': searchTerms.length > 1, 'open' : searchPanelShown}" @click.stop="toggleSearchPanel">
            <IconLight icon="sliders-up" />
            <span>{{$root.l10n('filter')}}</span>
            <div class="triangle"></div>
        </button>
        <Dropdown
            v-if="searchTerms.length > 1"
            id="searchTermDropdownWrapper"
            ref="searchTermDropdown"
            :align="ddAlign.topLeft"
            :size="ddSize.large"
            :scrollable="true"
            @toggle="t =>  t ? searchPanelShown = false : void 0"
        >
            <template #toggler="{toggle, toggler}">
                <button
                    id="searchTermDropdownButton"
                    type="button"
                    :class="toggler ? 'toggle' : null"
                    @click.stop="toggle"
                    v-tooltip="(searchTerms.length - 1) + ' ' +  $root.l10n('search_terms_active')"
                >
                    <span>{{ searchTerms.length - 1 }} </span><IconLight icon="chevron-up"/>
                </button>
            </template>
            <template #headline>
                {{$root.l10n('following_search_terms_are_selected')}}
            </template>
            <template #default="{toggle}">
                <AlertBox
                    v-for="({id,type,icon,title,text}) in searchTerms"
                    :key="id"
                    :type="type"
                    :icon="icon"
                    :title="title"
                    :text="text"
                >
                    <template #button>
                        <div class="alert-box-button">
                            <button v-if="icon === 'cog'" type="button" class="btn-std btn-icon-only" @click.stop="() => { toggle(); openSearchOptionPanel(); }">
                                <IconLight icon="edit"/>
                            </button>
                            <button v-else type="button" class="btn-std btn-icon-only" @click="() => { toggle(); deleteSearchTerm(id); }">
                                <IconLight icon="trash"/>
                            </button>
                        </div>
                    </template>
                </AlertBox>
            </template>
        </Dropdown>
        <div id="searchInputContainer">
            <div class="search-input-hidden-mirror" ref="searchInputHiddenMirror">
                {{ inputText }}
            </div>
            <div id="searchInputWrapper" @click="setFocusToInput" ref="searchInputWrapper">
                <div class="search-input-content"
                    :class="{show: showInputOverflow, overflow: searchIsOverflow}"
                    ref="searchInputContent"
                    @mouseenter="checkSearchOverflow"
                    v-click-outside="() => {showInputOverflow = false; this.searchIsOverflow = false}"
                >
                    <SearchWord v-for="searchWord in searchWords"
                        :key="searchWord.id"
                        @remove="removeSearchWord(searchWord.id)"
                        @update:modelValue="$nextTick(afterChange)"
                        v-model="searchWord.title"
                    />
                    <input
                        @focus.prevent="focus"
                        @input="change"
                        @blur="onBlur"
                        @keyup="onCompleteSearch"
                        @keydown.down.prevent="navigateCompleterOptions"
                        @keydown.up.prevent="navigateCompleterOptions"
                        @keydown.enter.stop="onCompleteSearchEnd"
                        @keydown.tab="onTabSearch"
                        @keydown.delete="onDelete"
                        @paste.prevent="onPaste"
                        id="searchInput"
                        ref="searchInput"
                        name="qt"
                        type="text"
                        v-model="inputText"
                        :disabled="!enabled"
                    />
                    <span v-if="(!active || !searchWords.length) && !inputText.length" class="placeholder">{{ $root.l10n('search_placeholder')}}</span>
                </div>
            </div>
            <button type="button" @click="clearSearch" class="search-delete" :disabled="!enabled" v-tooltip="$root.l10n('search_end')">
                <IconLight icon="xmark" />
            </button>
            <button type="button"
                v-if="searchUploadEnabled"
                class="search-upload"
                v-tooltip="$root.l10n('search_upload')"
                :disabled="!enabled"
                @click.prevent="toggleSearchUpload"
            >
                <IconLight icon="upload" />
            </button>
            <button type="submit" @click.prevent="search" class="search-btn search-start" :disabled="!enabled">
                <IconLight icon="search" />
                <span>{{$root.l10n('search_start')}}</span>
            </button>
        </div>
        <div v-if="searchSaveShown" class="search-presets-btn" @click="saveSearchPreset"
            v-tooltip="$root.l10n('save_current_search')">
            <IconLight icon="star" />
        </div>
        <div ref="favorites" v-if="searchFavoritesEnabled" class="btn-favorites" @click="showFavorites"
            v-tooltip="$root.l10n('show_favorites')">
            <IconSolid v-if="favoritesActive || favoritesAnimation" class="full" icon="heart"/>
            <IconLight v-else IconLight icon="heart"/>
        </div>
        <div v-if="searchPanelRequested"
            id="searchPanelLayer"
            :class="{open: searchPanelShown}"
        >
            <component :is="searchPanelComponent"
                v-bind="searchPanelArgs"
                v-model:filter="filter"
                v-model:settings="settings"
                :terms="searchPanelTerms"
                @update:filter="onFilterChanged"
                @update:settings="settingChanged"
                @terms="termsChanged"
                v-click-outside="hideSearchPanel"
                ref="searchPanel"
            />
        </div>
        <div v-if="searchUploadPanelShown && searchUploadEnabled"
            id="searchPanelLayer"
            :class="{open: searchUploadPanelShown}"
        >
            <div id="searchPanel" class="tab-container search-upload-container" v-click-outside="() => searchUploadPanelShown = false">
                <SimilarMediaUploader v-click-outside="() => searchCompleterShown = false"
                    @complete="onUpload"
                    @close="searchUploadPanelShown = false"
                    ref="uploader"
                />
            </div>
        </div>
        <div v-if="searchCompleterShown" id="searchPanelLayer" class="open">
            <div id="searchPanel" class="tab-container search-completer-dropdown" v-click-outside="() => searchCompleterShown = false">
                <AlertBox v-if="searchCompleterError" :type="'danger'" :text="searchCompleterError.message" />
                <div v-else id="searchPanelContent">
                    <LoadingSpinner v-if="searchCompleterOptions.length === 0" />
                    <div
                        v-for="({text, value, active}) in this.searchCompleterOptions" :key="value"
                        :class="{active}"
                        @click="selectSearchCompletion(value)"
                        @mousedown.prevent
                    >
                        <span v-html="text"></span>
                        <span><IconLight icon="search" /></span>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import {markRaw} from 'vue'
import IconLight from '../../../utility/IconLight'
import IconSolid from '../../../utility/IconSolid'
import SearchPanel from "./SearchPanel"
import ClickOutside from 'click-outside-vue3'
import EventBus from '../../../../lib/helpers/EventBus'
import DateInput from "@/components/forms/inputs/DateInput";
import AlertBox from "@/components/utility/AlertBox";
import ButtonStd from "@/components/forms/ButtonStd";
import searchText, {junction, sequence} from '../../../../lib/mixins/searchText'
import Dropdown, {ddSize, ddAlign} from '../../../utility/Dropdown'
import { objectAssignDeep } from '../../../../lib/utility'
import SearchWord from "@/components/layout/header/search/SearchWord"
import LoadingSpinner from '../../../layout/LoadingSpinner'
import localStorage from '../../../../lib/localStorage'
import ajax from '../../../../lib/ajax'
import SimilarMediaUploader from '@/components/utility/uploader/SimilarMediaUploader'

export default {
    name: "SearchBar",
    components: {
        SimilarMediaUploader,
        LoadingSpinner,
        SearchWord,
        ButtonStd,
        AlertBox,
        DateInput,
        Dropdown,
        SearchPanel,
        IconLight,
        IconSolid
    },
    mixins: [searchText],
    setup() {
        return {
            searchCompleterTimeout: null,
            ddAlign,
            ddSize
        }
    },
    data() {
        const closeOnMode = parseInt(this.$root.settings.DIALOG_CLOSE_ON, 10) || 2
        const searchCompleterDelay = this.$root.settings.SEARCH_AUTOCOMPLETE_ENABLED ?
            (this.$root.settings.SEARCH_AUTO_SUBMIT_DELAY || 0) : null

        return {
            enabled: false,
            focused: false,
            changed: false,
            searchCompleterDelay: searchCompleterDelay,
            searchCompleterEndpoint: null,
            searchCompleterShown: false,
            searchCompleterOptions: [],
            searchFavoritesEnabled: false,
            searchSaveEnabled: false,
            searchPanelShown: false,
            searchPanelRequested: false,
            searchPanelComponent: markRaw(SearchPanel),
            searchPanelArgs: {},
            searchUploadEnabled: false,
            searchUploadPanelShown: false,
            searchPlaceholder: this.$root.l10n('search_placeholder'),
            searchWords: [],
            favoritesActive: false,
            favoritesAnimation: false,
            active: false,
            searchIsOverflow: false,
            showInputOverflow: false,
            allowCloseOnEsc: closeOnMode === 2 || closeOnMode === 4,
            allowCloseOnOutsideClick: closeOnMode === 3 || closeOnMode === 4,
            filter: {
                //qt: ''
            },
            searchPanelTerms: {},
            searchTerms: [],

            settings: localStorage.getItem('sOpt') || {},
        }
    },
    computed: {
        selected () {
            return this.filter?.selected || 0
        },
        spTabs () {
            return {
                opt: this.$root.l10n('search_options')
            }
        },
        spTabsCount () {
            return Object.keys(this.spTabs).length
        },
        /**
         * @returns {Boolean}
         * @override searchText
         */
        emptyValue() {
            return this.searchWords.length === 0
        },
        /**
         * @returns {String}
         * @override searchInput
         */
        searchValue () {
            const wordCn = this.searchWords.length
            const p = wordCn > 1 ? '"%s"' : '%s'
            return wordCn > 0 ? this.getSearchValue(
                this.searchWords.map(w => w.title.indexOf(' ') > -1 ? p.replace('%s', w.title) : w.title).join(' '),
                this.getSettings()
            ) : ''
        },
        /**
         * @returns {String}
         */
        searchValueTerm () {
            return this.searchWords.length > 0 ?
                this.getSearchValue(this.searchWords.map(w => w.title).join('|'), this.getSettings()) : ''
        },
        /**
         * @returns {Boolean}
         */
        searchSaveShown () {
            return this.searchSaveEnabled && this.enabled && this.active
                && !this.$root.publicAccess
        },
        /** @return {Error|null} */
        searchCompleterError() {
            return this.searchCompleterShown instanceof Error ? this.searchCompleterShown : null
        }
    },
    directives: {
        clickOutside: ClickOutside.directive
    },
    methods: {
        onBlur() {
            this.addSearchWord(this.inputText)
            this.afterChange()

            console.log('onBlur', window.activeElement);
            this.$nextTick(() => {
                this.searchCompleterOptions = []
                this.searchCompleterShown = false
            })
        },
        onPaste(event) {
            this.addSearchWord(event.clipboardData.getData('text'), true)
            this.afterChange()
        },
        onDelete(e) {
            if (
                e.keyCode === 8 &&
                this.inputText.length === 0 &&
                this.searchWords.length > 0
            ) {
                this.searchWords.pop()
            }
        },
        onUpload(uuid) {
            this.searchUploadPanelShown = false
            this.__removeSearchWordByPrefix('UUID:')
            this.addSearchWord('UUID:' + uuid, false)
            this.afterChange()
        },
        change() {
            this.searchInputSizeHandler()
            if (
                this.settings.seq === sequence.partial &&
                this.inputText[this.inputText.length - 1] === ' '
            ) {
                if (this.addSearchWord(this.inputText) === true)
                    this.afterChange()
            }

            this.checkSearchOverflow()
        },
        /** */
        afterChange() {
            this.inputText = ''
            this.filter.qt = this.searchValue
            this.setSearchPanelQTerms()
            this.changed = !this.isSearchEmpty()
            if (this.$refs.searchInput) this.$refs.searchInput.style.width = "0ch"
        },
        /**
         * @param {string} text
         * @param {boolean} p
         */
        addSearchWord(text, p) {
            // reduce multiple white spaces in row to one
            text = text.trim().replace(/\s+/g, ' ')
            if (text.length === 0) return false
            let parts = [], test
            if (!p && this.settings.seq === sequence.exact || (test = text.match(/^[A-Z]{2,4}:/))) {
                parts = [text]
                if (test?.[0] === text) return false
            } else if (!p && this.settings.seq === sequence.partial) {
                parts = text.split(' ')
            } else if (!p && this.settings.seq === sequence.bounded) {
                parts = [text]
            } else {
                parts = text.replace(/".+?"/g, match => encodeURIComponent(match)).split(' ')
                    .map(s => s.replace(/%22(.+?)%22/g, (_,m) => decodeURIComponent(m)))
            }

            for (let i = 0; i < parts.length; i++) {
                if (parts[i].replace(/[\p{P}\p{S}\s]/gu, '').trim() === '') continue
                const prefix = parts[i].trim().match(/(^[A-Z]{2,4}):\s*/)?.[0]
                const sw = this.searchWords.find(sw => sw.title === parts[i]
                    || (prefix && sw.title.indexOf(prefix) === 0)
                )
                if (sw?.title === parts[i]) continue
                else if (sw && prefix) {
                    const tmp = sw.title.substring(prefix.length).split(',').map(v => v.trim())
                    sw.title = prefix + tmp.concat(
                        parts[i].substring(prefix.length).trim().split(',').map(v => v.trim())
                    ).filter((v,i,s) => v && s.indexOf(v) === i).join(', ')
                } else {
                    this.searchWords.push({
                        id: 'qt_' + Date.now() + i,
                        title: parts[i],
                        visible: true
                    })
                }
            }
            return true
        },
        /**
         * @param {number} searchWordId
         */
        removeSearchWord(searchWordId) {
            this.__removeSearchWord(searchWordId)
            this.afterChange()
        },
        /**
         * @param {number} searchWordId
         */
        __removeSearchWord(searchWordId) {
            for (let i = 0; i < this.searchWords.length; i++) {
                if (this.searchWords[i].id === searchWordId) {
                    this.searchWords.splice(i, 1);
                }
            }
        },
        /**
         * @param {string} prefix
         * @private
         */
        __removeSearchWordByPrefix(prefix) {
            for (let i = 0; i < this.searchWords.length; i++) {
                if (this.searchWords[i].title.indexOf(prefix) === 0) {
                    this.searchWords.splice(i, 1);
                }
            }
        },
        /** added QT to searchPanelTerms-Array, if not empty*/
        setSearchPanelQTerms () {
            if (this.searchValueTerm) {
                this.searchPanelTerms.qt = {
                    title: this.$root.l10n('search_free_text'), terms: this.searchValueTerm
                }
            } else if (this.searchPanelTerms.qt) {
                delete this.searchPanelTerms.qt
            }
        },
        focus () {
            if (this.active) return
            this.focused = true
        },
        setEnabled() {
            this.enabled = true
        },
        setDisabled() {
            this.searchSaveEnabled = false
            this.enabled = false
            this.filter = {}
        },
        setSearchSavable() {
            this.searchSaveEnabled = true
        },
        settingChanged(settings) {
            //this.settings = settings
            const parsed = this.getSettings()
            if (parsed.j) this.filter.or = 1
            else if (this.filter.or) delete this.filter.or
            const inputText = this.searchWords.map(s => '"' + s.title + '"').join(' ')
            this.filter.qt = this.getSearchValue(inputText, parsed)
        },
        /**
         * @param {string} text
         * @param {object} settings see SearchText.getSettings()
         * @returns {string}
         */
        getSearchValue(text, settings) {
            if (!text && settings.j) settings.j = ''
            return searchText.methods.getSearchValue.call(this, text, settings)
        },
        /**
         * @param {object} filter
         * @param {boolean} merge = false
         * @param {boolean} silent = false
         */
        setFilter(filter, merge = false, silent = false) {
            console.log("setFilter ", filter, merge ? 'merge' : 'replace', silent ? 'silent' : 'trigger')
            if (!this.enabled) return
            const empty = this.isSearchEmpty()

            if (!merge) {
                this.filter = filter ? JSON.parse(JSON.stringify(filter)) : {}
                this.searchPanelTerms = {}
            } else {
                this.filter = objectAssignDeep(this.filter || {}, filter)
            }
            let settings, value
            if (!this.filter.qt && Object.values(this.settings).length > 0) {
                this.filter.qt = value = ''
            } else {
                ({settings, value} = this.parseSearchValue(this.filter?.qt ? this.filter.qt : '**'))
                this.settings = Object.assign({}, this.settings, settings || {})
            }

            if (this.filter.or && this.settings.j !== junction.or) {
                this.settings.j = junction.or
            }
            this.inputText = ''
            this.searchWords = []
            this.addSearchWord(value, true)
            if (!silent) {
                if (!this.isSearchEmpty()) this.search()
                else if (!empty) this.clearSearch()
            }
        },

        /**
         * trigger search
         */
        search() {
            this.focused = false
            this.changed = false
            if (!this.isSearchEmpty()) {
                this.active = true
                this.searchPanelShown = false
                this.searchTerms = this.buildSearchTerms()
                this.filter = Object.assign({}, this.filter)
                if (this.filter.qt !== undefined && !this.filter.qt) delete this.filter.qt

                this.$store.state.searchIsActive = true
                localStorage.setItem('sOpt', this.settings)
                EventBus.$emit('search.change', this.filter, this.searchPanelTerms)
                EventBus.$emit('selection.show', !!this.selected)
            }
        },
        /**
         * @returns {boolean}
         */
        isSearchEmpty() {
            const filter = Object.assign({}, this.filter)
            if (filter.qt && this.searchWords.length > 0) return false
            delete filter.qt
            delete filter.or
            return this.checkEmptyRecursive(filter)
            /*(
                (!this.filter.selected) &&
                (typeof this.filter.qt === 'undefined' || this.filter.qt.length === 0) &&
                (this.checkEmptyRecursive(this.filter))
            )*/
        },
        /** */
        toggleSearch() {
            if (!this.isSearchEmpty()) this.search()
            else this.clearSearch()
        },
        /**
         * @param {any} filter
         * @returns {boolean}
         */
        checkEmptyRecursive(filter) {
            if (filter && typeof filter === 'object') {
                return !(filter instanceof Array ? filter : Object.values(filter))
                    .some(v => !this.checkEmptyRecursive(v))
            } else return null === filter || undefined === filter || String(filter).length === 0
        },
        /** */
        clearSearch() {
            this.focused = false
            this.changed = false
            this.active = false
            this.searchPanelShown = false
            this.inputText = ''
            this.searchWords = []
            this.searchTerms = []
            this.$store.state.searchIsActive = false
            EventBus.$emit('search.change', this.filter = {}, this.searchPanelTerms = {})
            EventBus.$emit('selection.show', !!this.selected)
        },
        /** */
        enterSearch(e) {
            if (e.keyCode === 13) { this.search() }
        },
        /**
         TO DO - Filter von der aktuellen Suche entfernen und Suche aktualisieren
         */
        deleteSearchTerm(id) {
            const chain = id.indexOf('.') > -1 ? id.split('.') : [id]
            let filter_leaf = this.filter
            for (let i = 0; i < chain.length -1; i++) {
                if (chain[i] in filter_leaf) {
                    filter_leaf = filter_leaf[chain[i]]
                } else {
                    filter_leaf = {}
                    break
                }
            }
            if (id in this.searchPanelTerms) {
                delete this.searchPanelTerms[id]
            }
            if (chain[chain.length-1] in filter_leaf) {
                const key = chain[chain.length-1]
                delete filter_leaf[key]
                this.toggleSearch()
            }

        },
        /**
         * @param {Promise.<Vue>} vc
         * @param {object} args
         */
        setSearchPanel(vc, args) {
            this.searchPanelComponent = markRaw(vc || SearchPanel)
            this.searchPanelArgs = args || {}
            this.searchPanelRequested = false
        },
        toggleSearchPanel () {
            this.searchPanelShown = !this.searchPanelShown
            this.searchPanelRequested = true
            this.$refs.searchTermDropdown?.toggleClose()
        },
        toggleSearchUpload () {
            this.searchPanelShown = false
            this.searchUploadPanelShown = !this.searchUploadPanelShown
            this.$refs.searchTermDropdown?.toggleClose()
        },
        openSearchOptionPanel () {
            if (!this.searchPanelRequested) {
                this.searchPanelRequested = true
                //cheating to await search panel is load and open
                const ti = setInterval(() => {
                    if (this.$refs.searchPanel?.tab !== undefined) {
                        this.$refs.searchPanel.tab = 'opt'
                        clearInterval(ti)
                    }
                }, 100)
            } else this.$refs.searchPanel.tab = 'opt'
            //setTimeout(() => this.$refs.searchPanel.tab = 'opt', 1000)
            this.searchPanelShown = true
            this.$refs.searchTermDropdown?.toggleClose()
        },
        onFilterChanged (...args) {
            this.changed = !this.isSearchEmpty()
            //todo: immediately search
        },
        /** @param {{title: string, terms: string}[]} terms */
        termsChanged(terms) {
            this.searchPanelTerms = Object.assign({},
                this.searchPanelTerms.qt ? {qt: this.searchPanelTerms.qt} : {},
                this.searchPanelTerms.category ? {category: this.searchPanelTerms.category} : {},
                terms
            )

            this.searchTerms = this.buildSearchTerms()
        },
        /** @returns {Object[]} */
        buildSearchTerms() {
            const terms = []
            terms.push({
                id: '__info__',
                type: 'info',
                icon: 'cog',
                title: '',
                text: this.getSettingsAsTerms()
            })

            for (let k in this.searchPanelTerms) {
                if (!this.getFilterByKey(k)) continue
                if (k === 'qt') {
                    const {value} = this.parseSearchValue(this.filter.qt)
                    if (!value) continue
                }
                const term = Object.assign(
                    {id: k, title: null, text: null},
                    this.parseSearchTerm(this.searchPanelTerms[k].terms, this.searchPanelTerms[k].title)
                )
                if (term.title) terms.push(term)
            }
            return terms
        },
        setFocusToInput(event) {
            if(event.target.classList.contains('search-word-item') || event.target.parentElement.classList.contains('search-word-item')) return
            this.$refs.searchInput.focus()
        },
        checkSearchOverflow() {
            const minHeight = parseInt(window.getComputedStyle(this.$refs.searchInputContent, null).getPropertyValue("min-height"), 10)
            this.showInputOverflow = true
            this.$nextTick(() => {
                if (this.$refs.searchInputContent.scrollHeight > minHeight) {
                    this.searchIsOverflow = true
                }
            })
        },
        searchInputSizeHandler() {
            const maxWidth = this.$refs.searchInputContent.clientWidth
            const input = this.$refs.searchInput
            const mirrorInputWidth = this.$refs.searchInputHiddenMirror.clientWidth
            input.style.width = mirrorInputWidth + "px"
            if (input.clientWidth >= maxWidth) this.onBlur()
        },
        onTabSearch(e) {
            console.log(e.code, e.key);
            this.searchCompleterShown = false
            if (this.inputText && this.addSearchWord(this.inputText) === true) {
                e.preventDefault()
                e.stopPropagation()
                this.afterChange()
            }
        },
        navigateCompleterOptions(e) {
            if (this.searchCompleterOptions.length === 0) return ;
            let ix = this.searchCompleterOptions
                .findIndex(o => o.active || o.value === this.inputText) + (e.keyCode === 38 ? -1 : +1)

            if (ix < 0) ix = this.searchCompleterOptions.length - 1
            else if (ix >= this.searchCompleterOptions.length) ix = 0
            this.searchCompleterOptions.forEach((v, i) => v.active = i === ix)
            return false
        },
        onCompleteSearchEnd(e) {
            const opt = this.searchCompleterOptions.find(({active}) => active)
            if (opt) this.inputText = opt.value

            this.onBlur()
        },
        onCompleteSearch(e) {
            if (!this.searchCompleterEndpoint) return ;
            if (!(
                (e.keyCode > 47 && e.keyCode < 58)   || // number keys
                (e.keyCode > 64 && e.keyCode < 91)   || // letter keys
                (e.keyCode > 95 && e.keyCode < 112)  || // numpad keys
                e.keyCode === 8 || e.keyCode === 46
            )) return ;

            if (this.searchCompleterTimeout) clearTimeout(this.searchCompleterTimeout)
            if (this.searchCompleterDelay > 0) {
                this.searchCompleterTimeout = setTimeout(() =>
                        this.completeSearch(e.target.value),
                    this.searchCompleterDelay
                )
            } else if (this.searchCompleterDelay !== null) {
                this.completeSearch(e.target.value)
            }
        },
        completeSearch(qt) {
            this.searchCompleterShown = qt.length > 2
            if (!this.searchCompleterShown) {
                this.searchCompleterOptions = []
            } else ajax.json(this.searchCompleterEndpoint, {
                data: {
                    term: qt,
                    max: 10,
                    second_min: 2	// minimale Länge des nachfolgenden Wortes
                }
            }).then(({response}) => {
                const qt_len = qt.length
                this.searchCompleterOptions = (response || []).map(it => ({
                    value: it.value,
                    text: it.value.substr(0, qt_len) + `<b>${it.value.substr(qt_len)}</b>`,
                    active: false
                }))
                this.searchCompleterShown = this.searchCompleterOptions.length > 0

            }).catch(e => this.searchCompleterShown = new Error(e.message || e.response || e))
        },
        selectSearchCompletion(qt) {
            this.inputText = qt
            this.onBlur()
        },
        setSearchCompleterEndpoint(endpoint) {
            this.searchCompleterEndpoint = endpoint
        },
        setSearchByUpload(state = false) {
            this.searchUploadEnabled = state
        },
        saveSearchPreset($event) {
            if (!this.searchSaveShown) return ;
            EventBus.$emit('searchPreset.saved', $event, this.filter)
        },
        setSearchFavorites(state = false) {
            this.searchFavoritesEnabled = state
        },
        showFavorites() {
            this.favoritesActive = !this.favoritesActive
            if (this.favoritesActive) EventBus.$emit('search.set', {favorites: true})
            else EventBus.$emit('search.set', {})
        },
        /**
         * @param {Event} $event
         */
        favoriteSaved($event) {
            console.log($event)
            let target = $event.target.classList.contains('svg-inline--fa') ? $event.target : $event.target.classList.contains('action-icon') ? $event.target.children[0] : $event.target.parentElement
            target.classList.add('no-events')
            this.$nextTick().then(() => {
                const destination = document.querySelector('body')
                const cloneSaveObject = document.importNode(target, true)
                cloneSaveObject.classList.add('btn-favorites-clone')
                destination.appendChild(cloneSaveObject)

                const saveBtnOffset = target.getBoundingClientRect()
                const topStart = saveBtnOffset.top
                const leftStart = saveBtnOffset.left

                let css = {top: topStart + "px", left: leftStart + "px"}
                Object.assign(cloneSaveObject.style, css)
                cloneSaveObject.classList.add('transition-position')

                const favoritesEl = this.$refs.favorites
                const favoritesOffset = favoritesEl.getBoundingClientRect()
                const topEnd = favoritesOffset.top
                const leftEnd = favoritesOffset.left

                css = {top: topEnd + "px", left: leftEnd + "px"}
                Object.assign(cloneSaveObject.style, css)
                favoritesEl.classList.add('animate-save')
                this.favoritesAnimation = true

                setTimeout(() => {
                    cloneSaveObject.parentNode.removeChild(cloneSaveObject)
                    favoritesEl.classList.remove('animate-save')
                    this.favoritesAnimation = false

                    this.$nextTick(() => {
                        target.classList.remove('no-events')
                    })
                }, 1000)
            })
        },
        /**
         * @param {string} key
         * @returns {any}
         */
        getFilterByKey (key) {
            if (key in this.filter) return this.filter[key]
            key = key.split('.')
            let f = this.filter
            for (let k of key) {
                if (k && k in f) f = f[k]
                else return null
            }
            return f
        },
        closeOnEsc(e) {
            if (this.allowCloseOnEsc && e.keyCode === 27) {
                this.searchPanelShown = false
            }
        },
        dragenter(e) {
            this.searchUploadPanelShown = this.searchUploadEnabled && true
        },
        dragleave(e) {
            if(e.clientX <= 0 || e.clientY <= 0 || (e.clientX >= e.innerWidth || e.clientY >= window.innerHeight)){
                console.log('dragleave from window');
                console.log('drag leave', e.target, e.currentTarget)
                this.searchUploadPanelShown = false
            }
        },
        hideSearchPanel(event) {
            let parent = event.target.parentNode
            while (parent.tagName !== 'BODY' && parent !== this.$el) {
                if (!(parent = parent.parentNode)) return ;
            }
            this.searchPanelShown = false
        }
    },
    watch: {
        filter(to) {
            this.favoritesActive = to.favorites
        }
    },
    created() {
        setTimeout(_ => {
            document.addEventListener('keydown', this.closeOnEsc)
        }, 0)
    },
    mounted() {
        EventBus.$on('search.set', this.setFilter)
        EventBus.$on('search.terms', this.termsChanged)
        EventBus.$on('search.enable', this.setEnabled)
        EventBus.$on('search.disable', this.setDisabled)
        EventBus.$on('search.savable', this.setSearchSavable)
        EventBus.$on('search.panel', this.setSearchPanel)
        EventBus.$on('search.completer', this.setSearchCompleterEndpoint)
        EventBus.$on('search.upload', this.setSearchByUpload)
        EventBus.$on('search.favorites', this.setSearchFavorites)
        EventBus.$on('favorite.saved', this.favoriteSaved)
        document.addEventListener('keydown', this.enterSearch)
        window.addEventListener('dragenter', this.dragenter)
        window.addEventListener('dragleave', this.dragleave)
    },
    unmounted() {
        EventBus.$off('search.set', this.setFilter)
        EventBus.$off('search.enable', this.setEnabled)
        EventBus.$off('search.disable', this.setDisabled)
        EventBus.$off('search.savable', this.setSearchSavable)
        EventBus.$off('search.panel', this.setSearchPanel)
        EventBus.$off('search.terms', this.termsChanged)
        EventBus.$off('search.completer', this.setSearchCompleterEndpoint)
        EventBus.$off('search.upload', this.setSearchByUpload)
        EventBus.$off('search.favorites', this.setSearchFavorites)
        EventBus.$off('favorite.saved', this.favoriteSaved)
        document.removeEventListener('keydown', this.enterSearch)
        document.removeEventListener('keydown', this.closeOnEsc)
        window.removeEventListener('dragenter', this.dragenter)
        window.removeEventListener('dragleave', this.dragleave)
    }
}
</script>

<style lang="scss">
#searchPanelLayer {
    /*#searchPanel.search-upload-container {
        position: fixed;
        top: 60px;
        left: 50%;
        width: 76vw;
        max-width: 41.90vw;
        transform: translateX(-50%);
        z-index: 100;

        #similarMediaPanelContent {
            position: relative;
            width: 100%;
            border-radius: 5px;
            overflow: hidden;
            background-color: var(--grey_10);
            z-index: 20;
            padding: 0 1rem 1rem 1rem;
        }
    }
*/
    #searchPanel.search-upload-container {
        top: 12px;
        width: 42%;
        margin-left: -21%;
    }
    #similarMediaPanelContent {
        border-radius: 18px;
        overflow: hidden;
        background-color: var(--grey_10);
        padding: 0 1.5rem 1.5rem 1.5rem;
    }
    .search-upload-container .headline {
        text-align: center;
        padding: 8px 0;
        color: var(--grey_60);
    }

    .search-upload-container .content {
        background-color: var(--white);
        border: 1px dashed var(--grey_20);
        padding: 4rem 2rem 3rem 2rem;

        .similar-media-upload {
            margin: 0 -8px;
        }

        .qq-upload-list-selector {
            display: none;
        }

        .line-back {
            text-align: center;
            position: relative;
            margin: 2rem 0;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                width: 100%;
                height: 1px;
                background-color: var(--grey_40);
            }

            span {
                display: inline;
                position: relative;
                z-index: 1;
                margin: auto;
                padding: 0 1rem;
                background-color: var(--white);
                text-transform: uppercase;
            }
        }

        .input-url {
            position: relative;

            input {
                background-color: var(--grey_10);
                height: 36px;
                border-radius: 18px;
                width: 100%;
                padding: 0 2rem;
            }

            svg {
                position: absolute;
                top: 50%;
                right: 1.5rem;
                transform: translateY(-50%);
                cursor: pointer;
                fill: var(--grey_40);
            }
        }
    }
}

#searchInputContainer {
    .search-input-hidden-mirror {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        visibility: hidden;
    }
}

@media only screen and (max-width: 1400px) {
    #searchPanelLayer #searchPanel.search-upload-container {
        width: 42.5%;
        margin-left: -24.3%;
    }
}


@media only screen and (max-width: 1200px) {
    #searchPanelLayer #searchPanel.search-upload-container {
        width: calc(100vw - 298px);
        left: 79px;
        margin-left: 0px;
    }
}

@media only screen and (max-width: 980px) {
    #searchPanelLayer #searchPanel.search-upload-container {
        width: calc(96vw - 250px);
        left: 69px;
    }
}

@media only screen and (max-width: 860px) {
    #searchPanelLayer #searchPanel.search-upload-container {
        width: calc(85vw - 145px);
    }
}

@media screen and (min-width: 601px) {
    #searchPanelLayer #searchPanel.search-upload-container {
        min-width: 395px;
    }
}

@media screen and (max-width: 600px) {
    #searchPanelLayer #searchPanel.search-upload-container {
        width: 100%;
        top: 60px;
        left: 0px;
    }
}


</style>