const AttributeTypes = require('./attributetypes')

/**
 * Attribute Interface
 * @property {Number|String} id
 * @property {String} type
 * @property {String} name
 * @property {Number} sort
 * @property {Boolean} multilingual
 * @property {String} placeholder
 * @property {String} helpText
 * @property {String} mask
 * @property {String} defaultValue
 * @property {any} functionSet
 * @property {any} additionalData
 */
class IAttribute {
    /**
     *
     * @param {Object} [args]
     */
    constructor(args) {
        if (this.constructor === IAttribute)
            throw new SyntaxError(`Interface ${this.constructor.name} cannot be instantiated directly`)
    }

    isPseudo () { return AttributeTypes.isPseudo(this.type) }
    isMeta () { return AttributeTypes.isMeta(this.type) }
    isModuleLink () { return AttributeTypes.isModuleLink(this.type) }
    isMultipleModuleLink () { return AttributeTypes.isMultipleModuleLink(this.type) }
    isCategory () { return AttributeTypes.isCategory(this.type) }
    isChoosable () { return AttributeTypes.isChoosable(this.type) }

    getCategoryId() { return AttributeTypes.isCategory(this.id) }
}

/**
 *
 */
class Attribute extends IAttribute {
    /**
     * @param {Object} [data]
     */
    constructor(data) {
        super()

        this.id = null
        this.type = null
        this.attributeType = null
        this.name = this.title = null
        this.deleted = null
        this.multilingual = null
        this.placeholder = null
        this.helpText = null
        this.mask = null
        this.defaultValue = null
        this.functionSet = null
        this.additionalData = null
        this.tenants = null

        this.createDate = null
        this.changeDate = null
        this.selected = null

        this.options = null
        if (data && Object.keys(data).length > 0) this.set(data)
    }

    /**
     * @param {Object} data
     */
    set(data) {
        const id = data.Id || data.id || null
        if (id !== null) this.id = isNaN(id) ? id + '' : parseInt(id,10)

        this.type = data.Type || data.type || ''
        this.attributeType = data.attributeType || ''
        this.createDate = data.CreateDate || data.createDate || null
        this.changeDate = data.ChangeDate || data.changeDate || null
        this.name = data.Name || data.name || ''
        this.title = this.title = data.Title || data.title || this.name
        this.deleted = !!(data.Deleted || data.deleted)
        this.multilingual = !!(data.Multilingual || data.multilingual)
        this.placeholder = data.Placeholder || data.placeholder || ''
        this.helpText = data.HelpText || data.helpText || ''
        this.mask = data.Mask || data.mask || ''
        this.defaultValue = data.DefaultValue || data.defaultValue || ''
        this.functionSet = data.FunctionSet || data.functionSet || null
        this.additionalData = data.AdditionalData || data.additionalData || null
        this.tenants = data.Tenants || data.tenants || ''
        /*
        "AdditionalData": {
            "HtmlEditorButtons": "bold,italic,underline,insertLink"
            "MetaTag": "Caption,Caption-Abstract,Headline"
        }*/


        this.selected = data.Selected || data.selected || null

        const options = (data.Options||data.options)
        this.options = options instanceof Array ? options.map(o => new AttributeOption(o)) : null
    }

    get created () { return this.createDate }
    get changed () { return this.changeDate }

    // extend Attribute with static methods and properties
    static isPseudo = AttributeTypes.isPseudo
    static isMeta = AttributeTypes.isMeta
    static isModuleLink = AttributeTypes.isModuleLink
    static isCategory = AttributeTypes.isCategory
    static getCategoryId = AttributeTypes.getCategory

    static isMetaNumeric = AttributeTypes.isMetaNumeric
    static getMetaKey = AttributeTypes.getMetaKey
    static getChoosableTypes = AttributeTypes.getChoosableTypes
    static isChoosable = AttributeTypes.isChoosable
    static TYPE = AttributeTypes.TYPE
}

/**
 *
 */
class AttributeOption {
    /**
     * @param {?Object} data
     */
    constructor(data) {
        this.value = null
        this.title = null
        this.color = null
        this.group = null

        if (data && Object.keys(data).length > 0) this.set(data)
    }

    /**
     * @param {Object} data
     */
    set(data) {
        this.value = data.Value || data.value || null
        this.title = data.Title || data.title || 'undefined'
        this.color = data.Color || data.color || null
        this.group = data.Group || data.group || null
    }
}

/**
 *
 */
class RelatedAttribute extends IAttribute {
    /**
     * @param {Object} [data]
     */
    constructor (data) {
        super()

        if (this.constructor === RelatedAttribute)
            throw new SyntaxError(`Abstract class ${this.constructor.name} cannot be instantiated directly`)

        if (data instanceof RelatedAttribute) this.attribute = data.attribute
        else if (data instanceof IAttribute) this.attribute = data
        else this.attribute = new Attribute(data)

        this.required = data.Required || data.required || false
        this.unique = data.Unique || data.unique || false
        this.perm = {
            read: data.ReadPerm || data.perm?.read || false,
            write: data.WritePerm || data.perm?.write || false
        }
    }

    /** @returns {Number|String} */
    get id() { return this.attribute.id }
    /** @param {Number|String} id */
    set id(id) { this.attribute.id = id }

    /** @preturns {String} */
    get type() { return this.attribute.type }
    /** @param {String} type */
    set type(type) { this.attribute.type = type }
    /** @returns {String} */
    get name() { return this.attribute.name }
    /** @param {String} name */
    set name(name) { this.attribute.name = name }
    /** @returns {String} */
    get title() { return this.attribute.title }
    /** @param {String} name */
    set title(name) { this.attribute.title = name }
    /** @preturns {Boolean} */
    get multilingual() { return this.attribute.multilingual }
    /** @param {Boolean} ml */
    set multilingual(ml) { this.attribute.multilingual = ml }
    /** @returns {String} */
    get placeholder() { return this.attribute.placeholder }
    /** @param {String} ph */
    set placeholder(ph) { this.attribute.placeholder = ph }
    /** @returns {String} */
    get helpText() {return this.attribute.helpText }
    /** @param {String} text */
    set helpText(text) { this.attribute.helpText = text }
    /** @returns {String} */
    get mask() { return this.attribute.mask }
    /** @param {String} mask */
    set mask(mask) { this.attribute.mask = mask }
    /** @returns {String} */
    get defaultValue() { return this.attribute.defaultValue }
    /** @param {String} value */
    set defaultValue(value) { this.attribute.defaultValue = value }
    /** @returns {String} */
    get functionSet() { return this.attribute.functionSet }
    /** @param {String} fs */
    set functionSet(fs) { this.attribute.functionSet = fs }
    /** @returns {Any} */
    get additionalData() { return this.attribute.additionalData }
    /** @param {Any} data */
    set additionalData(data) { this.attribute.additionalData = data }

    /** @returns {Array} */
    get options() { return this.attribute.options }
    /** @param {Array} data */
    set options(data) { this.attribute.options = data }

    /** @return {boolean} */
    get readable () { return this.perm.read }
    /** @param {boolean} v */
    set readable (v) { this.perm.read = !!v }

    /** @return {boolean} */
    get writable () { return this.perm.write }
    /** @return {boolean} v */
    set writable (v) { this.perm.write = !!v }
}


/**
 *
 */
class ModuleTabAttribute extends RelatedAttribute {
    /**
     * @param {Object} [data]
     */
    constructor (data) {
        data = data || {}

        super(data)
    }
}
/* const a = [
    { attribute: {},
        values: [
            { lg : "de_DE", value : "Option1, Option2, Options3", rawValue: '1,2,3' }
        ]
    },
    { attribute: {},
        values: [
            { lg : "de_DE",
                value : User,
                toString() {}
            }
        ]
    },
    { attribute: {},
        values: [
            {
                lg : "de_DE",
                value : '0.123,2.355' ,
                valueText: 'Attach.jpg, Media / Kategorie / Subcat, Media / Projekte / Subprj: Media.jpg',
                collection: [{},{}]
            }
        ]
    },
] */
/**
 *
 */
class ObjectAttribute extends RelatedAttribute {
    /**
     * @param {Object} [data]
     */
    constructor (data) {
        data = data || {}

        super(data)
        this.value = data.Value || data.value || null
        if (this.value) this.value = Object.assign({}, this.value)//force to object
    }
    /** */
    setValue(value, locale) {
        //todo: factory
        this.value = value
    }
    /** */
    getPostValue(locale) {
        if (this.id === Attribute.TYPE.PSEUDO_TRACKS) {
            if (this.value[locale]?.raw?.subtitles?.raw != null || this.value[locale]?.raw?.chapters?.raw != null)
                return (this.value[locale].raw.subtitles.raw ?? "") + "," + (this.value[locale].raw.chapters.raw ?? "")
        }
        return this.value[locale]?.raw
    }
    /**
     * @param {ObjectAttribute[]} attributes
     * @param {?{locale: string}[]} languageSet
     * @returns {{attr: object, category?: object}}
     */
    static getPostValues (attributes, languageSet = []) {
        const data = {attr: {}}
        attributes.forEach((a) => {
            if (a.isCategory()) {
                const cid = a.id.substr(Attribute.TYPE.PSEUDO_CATEGORY.length + 1)
                if (!data.category) data.category = {}
                data.category[cid] = a.value[0].raw

            } else if (!a.isPseudo() || ObjectAttribute.editablePseudo.indexOf(a.id) !== -1) {
                if (a.multilingual && languageSet === null && Object.keys(a.value).length === 1) {
                    data.attr[a.id] = a.getPostValue(Object.keys(a.value)[0])
                } else if (a.multilingual && languageSet?.length === 1) {
                    data.attr[a.id] = a.getPostValue(languageSet[0].locale)
                } else if (a.multilingual && (languageSet === null || languageSet?.length > 1)) {
                    const d2 = {}

                    for (const l in a.value) d2[l] = a.getPostValue(l)
                    data.attr[a.id] = d2

                } else if (a.id === Attribute.TYPE.PSEUDO_GDPR_USERS) {
                    if (a.value[0]?.object) {
                        const object = a.value[0].object
                        const users = object.users?.map(v => v.Id).filter(v => !!v) || []
                        if (object.project?.Private) {
                            const files = Object.fromEntries(Object.entries(object.files || {}).map(([id, dat]) => [id, dat?.name]))
                            data.expiry = object.expiry
                            data.uploads = Object.assign(files, object.uploads || {})
                            data.relation = object.relation
                            data.gdpr_users = object.gdpr_users || null
                            data.attr[a.id] = {
                                u: users,
                                p:  'i' + (object.project?.Id || '0'),
                                e: object.project?.ExpiryDate || null,
                                usg: object.usages || [],
                            }

                        } else if (object.project?.Id) {
                            data.attr[a.id] = {u: users, p: object.project?.Id}
                        } else {
                            data.attr[a.id] = users
                        }

                    } else data.attr[a.id] = ''

                } else data.attr[a.id] = a.getPostValue(0)
            }
        })
        return data
    }
    /** */
    static editablePseudo = [
        Attribute.TYPE.PSEUDO_FILENAME,
        Attribute.TYPE.PSEUDO_CATEGORY,
        Attribute.TYPE.PSEUDO_GDPR_USERS,
        Attribute.TYPE.PSEUDO_SNIPPET,
        Attribute.TYPE.PSEUDO_PERMALINK,
        Attribute.TYPE.PSEUDO_TRACKS,
        Attribute.TYPE.PSEUDO_PREVIEW
    ]

    /**
     * @param {Attribute|object} attribute
     * @param {object|ObjectAttribute[]} attributeValues
     * @returns {ObjectAttribute}
     */
    static create(attribute, attributeValues) {
        const attr = new ObjectAttribute(attribute)
        const d = (attributeValues instanceof Array ?
            attributeValues.find(a => a.id === attr.id) : attributeValues[attr.id]) || {}
        attr.setValue(d.Value || d.value || null)
        return attr
    }

    /**
     * @param {ObjectAttribute[]} attributes
     * @param {object[]} languageSet
     */
    static extendValues(attributes, languageSet) {
        //@todo: avoid null use ObjectAttributeValue
        for (let i = 0; i < attributes.length; i++) {
            const attribute = attributes[i]
            attribute.value = Object.assign({}, attribute.value || {}) //force to object
            if (attribute.multilingual && languageSet.length > 0) {
                for (let i = 0; i < languageSet.length; i++) {
                    const locale = languageSet[i].locale
                    if (!attribute.value[locale]) attribute.value[locale] = { raw: '', text: '' }
                }
            } else if (!attribute.value['0']) {
                attribute.value['0'] = { raw: '', text: '' }
            }
        }
    }

    /**
     * @param {RelatedAttribute[]} attributes
     * @param {Object|Object[]} data
     * @returns {ObjectAttribute[]}
     */
    static createMultiple (attributes, data) {
        return attributes.map(function(a) {
            return ObjectAttribute.create(a, data || {})
        })
    }
}

module.exports = {
    Attribute,
    AttributeOption,
    IAttribute,
    RelatedAttribute,
    ModuleTabAttribute,
    ObjectAttribute
}