<template>
    <div id="moduleTabs" class="tab-menu-container" :class="lines > 1 && moduleTabsExpanded ? 'tab-menu-expanded' : null">
        <div v-if="list.length > 0" class="tab-menu" :class="lines > 2 ? 'tab-menu-overflow' : null">
            <div v-if="title" class="tab-menu-title">
                <span> {{ title }} </span>
                <div class="tab-menu-title-arrow"></div>
            </div>
            <div v-if="list[0].id === 'all'" id="allMediaTab" class="tab-menu-title" :class="list[0].id == active ? 'active' : null">
                <a :href="list[0].link" :disabled="list[0].disabled || null" @click.prevent="go(list[0].link)">
                    <span class="counter" v-if="list[0].counter === null"><IconDuotone icon="spinner-third" :spin="true" /></span>
                    <span class="counter" :class="searchIsActive && list[0].counter > 0 ? 'active' : null" v-else-if="list[0].counter !== undefined">{{ list[0].counter }}</span>
                    <span>&nbsp;{{$root.l10n('files')}}</span>
                </a>
                <div class="tab-menu-title-arrow"><IconCustom icon="arrow-right-big"/></div>
            </div>
            <ul class="list-inline list-horizontal" ref="tabMenuInlineList">
                <template v-for="(moduleTab, ix) in list">
                    <li
                        v-if="moduleTab.id !== 'all'"
                        :key="ix"
                        :class="[moduleTab.id == active ? 'active' : null, searchIsActive && moduleTab.counter > 0 ? 'has-search-results' : null]"
                        ref="moduleTabs"
                        class="list-inline-item"
                        v-bind="$root.addTestLabel('moduleTab-'+moduleTab.id)"
                    >
                        <a :href="moduleTab.link" :disabled="moduleTab.disabled || null" @click.prevent="go(moduleTab.link)">
                            {{ moduleTab.title || moduleTab.name }}
                            <span class="counter" v-if="moduleTab.counter === null"><IconDuotone icon="spinner-third" :spin="true" /></span>
                            <span class="counter" :class="searchIsActive && moduleTab.counter > 0 ? 'active' : null" v-else-if="moduleTab.counter !== undefined">{{ moduleTab.counter }}</span>
                        </a>
                    </li>
                </template>
            </ul>
            <div v-if=" lines > 1"  class="tab-menu-buttons" :class="lines > 2 ? 'tab-menu-overflow' : null">
                <div
                    class="tab-menu-expand-button"
                    :class="expandButtonCounter > 0 && searchIsActive && !moduleTabsExpanded ? 'has-more-search-results' : null"
                    v-tooltip="expandButtonCounter > 0 ? expandButtonCounter + ' ' + $root.l10n('show_more') : null"
                    @click="expandModuleTabs"
                >
                    <IconLight icon="angles-down"/>
                </div>
                <div
                    v-if="indexDropdowns > 0"
                    :class="showDropdown ? 'active' : null"
                    class="tab-menu-dropdown-wrapper"
                    ref="tabDropdown"
                    v-click-outside="hideDropdown">
                    <button class="tab-menu-dropdown-button" @click="showDropdown = !showDropdown">
                        <IconLight icon="caret-down" />
                        <span class="counter" v-if="dropdownCount === null">
                            <IconDuotone icon="spinner-third" :spin="true" />
                        </span>
                        <span class="counter" v-else-if="dropdownCount !== undefined">{{ dropdownCount }}</span>
                    </button>
                    <div v-show="showDropdown" class="triangle"></div>
                    <ul  v-show="showDropdown" class="tab-menu-dropdown" @click="hideDropdown" ref="tabMenuDropdownList" ></ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconLight from '../../utility/IconLight.vue'
import IconDuotone from '../../utility/IconDuotone.vue'
import EventBus from '../../../lib/helpers/EventBus.js'
import {directive as ClickOutside} from 'click-outside-vue3'
import IconCustom from "@/components/utility/IconCustom.vue";

export default {
    name: "ModuleTabs",
    components: {
        IconCustom,
        IconLight,
        IconDuotone
    },
    emits: {click: null},
    props: {
        list: {
            type: Array,
            default: () => []
        },
        active: {
            default: "0"
        },
        title: String,
        propModuleTabsExpanded: Boolean
    },
    data() {
        return {
            showDropdown: false,
            lines: 1,
            indexSecondLine: -1,
            indexDropdowns: -1,
            searchIsActive: this.$store?.state.searchIsActive || false,
            moduleTabsExpanded: this.propModuleTabsExpanded || true
        }
    },
    directives: {
        ClickOutside
    },
    computed: {
        tabsHasCounter() {
            return this.list.findIndex(tab => tab.counter !== undefined) !== -1
        },
        listIndexOffset() {
            return this.list.length > 0 && this.list[0].id === 'all' ? 1 : 0
        },
        dropdownCount() {
            return this.getCounterFromIndex(this.indexDropdowns)
        },
        expandButtonCounter() {
            return this.getCounterFromIndex(this.indexSecondLine)
        }
    },
    methods: {
        getCounterFromIndex(startingIndex) {
            let counter = null
            if(this.tabsHasCounter && this.list && startingIndex >= 0) {
                for (let i = startingIndex + this.listIndexOffset; i < this.list.length; i++) {
                    counter += this.list[i].counter
                }
            } else counter = undefined
            return counter
        },
        go(link) {
            if (link) this.$emit('click', link)
        },
        resizeModuleTabs() {
            this.lines = 1

            this.$nextTick(() => {
                const tabMenu = this.$refs.tabMenuInlineList
                const tabMenuItems = this.$refs.moduleTabs || []
                // clear unsynced items
                for (let i = tabMenu?.children?.length - 1; i >= 0; i--) {
                    if (tabMenuItems.indexOf(tabMenu.children[i]) === -1)
                        tabMenu.removeChild(tabMenu.children[i])
                }

                if (!tabMenuItems.length) return
                let lineHeight = tabMenuItems[0].clientHeight

                // tabs von dropdown zurück zu tab inline menu
                for (let tab of tabMenuItems) tabMenu.appendChild(tab)

                let sHeight = tabMenu.scrollHeight
                if (sHeight - 10 > lineHeight) {
                    this.lines = 2
                    this.indexSecondLine = tabMenuItems.findIndex(tab => tab.offsetTop >= lineHeight)
                }

                if (sHeight > 2 * lineHeight) {
                    this.lines = 3
                    this.indexDropdowns = tabMenuItems.findIndex(tab => tab.offsetTop >= 2 * lineHeight)

                    if(this.indexDropdowns > 0) {
                        this.$nextTick(() => {
                            for(let tab of tabMenuItems.slice(this.indexDropdowns)) {
                                this.$refs.tabMenuDropdownList?.appendChild(tab)
                            }
                        })
                    }
                }
            })
        },
        expandModuleTabs() {
            this.moduleTabsExpanded = !this.moduleTabsExpanded
            localStorage.setItem('moduleTabsExpanded', JSON.stringify(this.moduleTabsExpanded))
        },
        onSearchChanged(filter) {
            //this.searchIsActive = Object.keys(filter).length !== 0
            this.searchIsActive = this.$store.state.searchIsActive
        },
        closeDropdownsOnEsc(e) {
            if (e.keyCode === 27) this.hideDropdown()
        },
        hideDropdown() {
            this.showDropdown = false
        },
    },
    watch: {
        list: {
            deep: true,
            handler(to) {
                if (to.findIndex(tab => tab.counter === null) === -1 || this.tabsHasCounter === false) {
                    this.resizeModuleTabs()
                }
            }
        },
        propModuleTabsExpanded(bool) {
            return this.moduleTabsExpanded = bool
        }
    },
    mounted() {
        let contextStorage = require('@/lib/localStorage').getContext('moduleTabsExpanded')
        this.moduleTabsExpanded = localStorage.getItem('moduleTabsExpanded') ? JSON.parse(localStorage.getItem('moduleTabsExpanded')) : true
        this.resizeModuleTabs()
        window.addEventListener('resize', this.resizeModuleTabs)
        EventBus.$on('sidebar.resize', this.resizeModuleTabs)
        EventBus.$on('search.change', this.onSearchChanged)
        document.addEventListener('keydown', this.closeDropdownsOnEsc)
    },
    unmounted() {
        EventBus.$off('sidebar.resize', this.resizeModuleTabs)
        EventBus.$off('search.change', this.onSearchChanged)
        window.removeEventListener('resize', this.resizeModuleTabs)
        document.removeEventListener('keydown', this.closeDropdownsOnEsc)
    }
}
</script>