const { IAttribute, Attribute, ObjectAttribute } = require('./attribute')

/**
 * Object Interface
 * @property {Number} id
 * @property {Number} moduleTabId
 * @property {String} name
 * @property {ObjectAttribute[]} attributes
 */
class IObject {
    /**
     *
     * @param {Object} [args]
     */
    constructor(args) {
        if (this.constructor === IObject)
            throw new SyntaxError(`Interface ${this.constructor.name} cannot be instantiated directly`)

        this.id = null
        this.moduleTabId = null
        this.attributes = null
        this.name = null
        this.rights = null
        this.handlingSet = null
        this.status = null
        this.masterId = null
        this.content = null

        if (typeof args !== 'undefined') this.set(args)
    }

    /**
     * @param {Object} data
     */
    set(data) {
        const id = data.Id || data.id || null
        if (id !== null) this.id = parseInt(id,10)

        this.masterId = parseInt(data.MasterId || data.masterId || data.Id || data.id, 10) || null
        this.moduleTabId = IObject.getModuleTabId(data)
        //this.creatorId = data.CreatorId || data.creatorId || null
        //this.createData = data.CreateDate || data.createDate || null
        //this.changeDate = data.ChangeDate || data.changeDate || null
        this.name = data.Name || data.name || ''
        this.rights = data.Rights || data.rights || ''
        this.attributes = setAttributes(data.Attributes || data.attributes || [])
        this.variants = setVariants(data.Variants || data.variants || [], this)
        this.handlingSet = data.HandlingSet || data.handlingSet || {}
        this.status = data.Status || data.status || {Removed: 0, Archived: 0, Locked: 0}
        this.comment = data.Comment || data.comment || ''

        if (data.AttributeValues) {
            this.attributes.forEach(a => {
                if (a.id in data.AttributeValues) {
                    if (typeof data.AttributeValues[a.id] === 'object') {
                        // force [value] to {0: {raw: value}}
                        // force {de: value} to {de: {raw: value}}

                        a.value = {}
                        for (let lg in data.AttributeValues[a.id]) {
                            const value = data.AttributeValues[a.id][lg]
                            a.value[lg] = (typeof value === 'object' && 'raw' in value) ?
                                {
                                    raw: value.raw,
                                    text: value.text || value.raw,
                                    object: value.object || undefined
                                } : {
                                    raw: value, text: value
                                }
                        }


                    } else a.value = {0: {raw: data.AttributeValues[a.id]}}
                }
            })
        }
    }

    /**
     * @returns {string}
     */
    get createDate () {
        return this.findAttributeTextValue(Attribute.TYPE.PSEUDO_CREATE_DATE)
    }

    /** @returns {string} */
    get createDateTime () {
        return this.findAttributeValue(Attribute.TYPE.PSEUDO_CREATE_DATE)?.raw || ''
    }

    /**
     * @returns {string}
     */
    get changeDate () {
        return this.findAttributeTextValue(Attribute.TYPE.PSEUDO_EDIT_DATE)
    }

    /**
     * @returns {boolean}
     */
    isRemoved() { return !!this.status.Removed }

    /**
     * @returns {boolean}
     */
    isArchived() { return !!this.status.Archived }

    /**
     * @returns {boolean}
     */
    isLocked() { return !!this.status.Locked }

    /**
     * @returns {boolean}
     */
    isRejected() { return !!this.status.Rejected }

    /**
     * @returns {boolean}
     */
    isActive() { return !this.isRemoved() && !this.isArchived() && !this.isLocked() }

    /**
     * @returns {boolean}
     */
    isSearched() { return !!this.status.Searched }

    /**
     * @returns {boolean}
     */
    isMaster() { return this.id === this.masterId}

    /**
     * @returns {?{Id: Number, Parent: Number, Name: String}}
     */
    get variant () {
        const attribute = this.attributes.find(a => a.type === Attribute.TYPE.PSEUDO_VARIANT)
        if (attribute?.value && attribute.value[0] && attribute.value[0].object) {
            /* {"raw": number, "text": string, "object": { ... } */
            const variants = attribute.value[0].object || []
            return variants[variants.length-1]
        } return null
    }

    /**
     * @returns {?Number}
     */
    get variantId () {
        return this.variant?.Id ? parseInt(this.variant.Id, 10) : null
    }

    /**
     * @param {string} state
     * @returns {boolean}
     */
    checkByState(state) {
        return (state === 'active' && this.isActive())
            || (state === 'archived' && this.isArchived())
            || (state === 'locked' && this.isLocked())
            || (state === 'removed' && this.isRemoved())
    }
    /**
     * @param {string|number} aid
     * @returns {?string|?number}
     * @todo: class AttributeCollection, attributes.findById()
     */
    findAttribute(aid) {
        aid = '' + aid
        for (let i = 0; i < this.attributes.length; i++) {
            const attribute = this.attributes[i]
            if (String(this.attributes[i].id) === aid)
                return this.attributes[i]
        }
        return null
    }
    /**
     * @param {string|number} aid
     * @param {string|0} locale
     * @returns {?string|?number}
     * @todo: class AttributeValue
     */
    findAttributeValue(aid, locale) {
        const attribute = this.findAttribute(aid)
        if (attribute && attribute.value) {
            const locales = attribute.multilingual ? Object.keys(attribute.value) : [0];
            const language = locales.length !== 1 ? locales.indexOf(locale) >= 0 ? locale : -1 : locales[0];
            if (
                attribute.value &&
                attribute.value[language] &&
                attribute.value[language].raw
            ) return attribute.value[language]
        }
        return null
    }
    /**
     * @param {string|number} aid
     * @param {string|0} locale
     * @returns {?string|?number}
     * @todo: class AttributeValue
     */
    findAttributeTextValue(aid, locale) {
        const value = this.findAttributeValue(aid, locale)
        if (value) return value.text || value.raw
        else return null
    }

    /**
     * @param {string} handlingValue
     */
    addMarkedHandling(handlingValue) {
        if (!this.handlingSet.Marked) this.handlingSet.Marked = []
        this.handlingSet.Marked.push(handlingValue)
    }

    /**
     * @param handlingValue
     */
    removeMarkedHandling(handlingValue) {
        if (!this.handlingSet.Marked) return ;
        const parts = handlingValue.split('/') // ~ 123/LP: 23
        for (let i = this.handlingSet.Marked.length - 1; i >=0; i--) {
            let markedX = this.handlingSet.Marked[i]
            let partsX = markedX.split('/') //  ~ 123/ or  123/LP: 23 or 123/LP: 23/LP: 34 ...
            let uid = partsX.splice(0, 1)[0]
            if (parts[0] === uid) {
                partsX = partsX.filter(lp => !parts.slice(1).some(pp => lp === pp))
                if (partsX.length === 0) {
                    this.handlingSet.Marked.splice(i, 1)
                } else this.handlingSet.Marked[i] = uid + '/' + partsX.join('/')
            }
        }
    }

    /**
     * @param {number} userId
     * @param {number} presetId
     * @return {boolean}
     */
    checkMarked(userId) {
        return this.handlingSet?.Marked?.includes(userId + '/')
    }

    /**
     * @param {number} userId
     * @param {boolean} excepted
     * @return {boolean}
     */
    checkNetdrive(userId = null, excepted = false) {
        const r = !!this.handlingSet?.Netdrive?.some(s => userId ? s.indexOf(userId + '/') === 0 : !!s)
        if (userId && excepted) {
            return !!this.handlingSet?.Netdrive?.some(s => s && s.indexOf(userId + '/') === -1)
        } else return r
    }

    /**
     * @param {number} userId
     * @return {boolean}
     */
    getNetdriveInfo(userId) {
        const needle = userId + '/'
        return this.handlingSet?.Netdrive?.find(s => s.indexOf(needle) === 0)?.substring(needle.length)
    }

    /**
     * @param {Object} data
     * @returns {number}
     */
    static getModuleTabId(data) {
       return parseInt(data.moduleTabId || data.ModuleId || 0, 10)
    }

    /**
     * @param {Object} data
     * @param {RelatedAttribute[]} attributes
     */
    static create (data, attributes) {
        attributes = ObjectAttribute.createMultiple(attributes, data.Attributes || data.attributes)
        return new this(Object.assign({}, data, {attributes: attributes, Attributes: attributes}))
    }

    /**
     * @param {object} data
     * @return {IObject}
     */
    static factory(data) {
        if (data instanceof IObject) return data
        switch (data.ClassType) {
            case 'FileObject': return new FileObject(data)
            case 'MediaObject': return new MediaObject(data)
            case 'CRMObject': return new CRMObject(data)
            case 'EntityObject': return new EntityObject(data)
            default: throw new SyntaxError(`Unknown class type ${data.ClassType}`)
        }
    }
}

/**
 *
 */
class FileObject extends IObject {

    /**
     * @param {Object} data
     */
    set(data) {
        super.set(data)
        this.referenced = !!(data.Referenced || data.referenced)
        if (!this.attributes.find(a => a.id === Attribute.TYPE.PSEUDO_PREVIEW)?.value) {
            extendAttributeCollection(this, Attribute.TYPE.PSEUDO_PREVIEW, [{
                raw: data.AttachmentId || 0,
                title: data.OriginalName || null,
                object: {Rendered: data.Preview ? 1 : 0, Preview: data.Preview, Restricted: true}
            }])
        }
    }

    /**
     * @returns {?Object}
     */
    get originalName () {
        return this.attributes.find(a => a.id === Attribute.TYPE.PSEUDO_FILENAME)?.value?.[0]?.raw || null;
    }

    /**
     * @returns {?Object}
     */
    get fileExt () {
        return this.attributes.find(a => a.id === Attribute.TYPE.META_EXTENSION)?.value?.[0]?.raw || null;
    }

    /**
     * @return {number}
     */
    get size() {
        const attribute = this.attributes.find(a => a.type === Attribute.TYPE.META_SIZE)
        return attribute?.value?.[0].raw || 0
    }

    /**
     * @return {number}
     */
    get previewRendered () {
        const attribute = this.attributes.find(a => a.id === Attribute.TYPE.PSEUDO_PREVIEW)
        return (attribute?.value && attribute.value && attribute.value[0] && attribute.value[0].object) ?
            attribute.value[0].object.Rendered : -1;
    }

    /**
     * @returns {?Object}
     */
    get preview () {
        const attribute = this.attributes.find(a => a.id === Attribute.TYPE.PSEUDO_PREVIEW)
        return (attribute?.value && attribute.value[0] && attribute.value[0].object) ?
            attribute.value[0].object.Preview : null;
    }

    /**
     * @returns {boolean}
     */
    get previewRestricted() {
        const attribute = this.attributes.find(a => a.id === Attribute.TYPE.PSEUDO_PREVIEW)
        return (attribute?.value && attribute.value[0] && attribute.value[0].object) ?
            attribute.value[0].object.Restricted : false;
    }

    get previewWatermark() {
        if (this.preview?.video || this.preview?.audio || this.ebook || this.preview?.spatial) return null
        const attribute = this.attributes.find(a => a.id === Attribute.TYPE.PSEUDO_WATERMARK)
        return (attribute?.value && attribute.value[0] && attribute.value[0].raw) ?
            attribute.value[0].raw.split(",")[0] : null;
    }

    get previewLargeWatermark() {
        if (this.preview?.video || this.preview?.audio || this.ebook || this.preview?.spatial) return null
        const attribute = this.attributes.find(a => a.id === Attribute.TYPE.PSEUDO_WATERMARK)
        return (attribute?.value && attribute.value[0] && attribute.value[0].raw) ?
            attribute.value[0].raw.split(",")[1] : null;
    }

    /**
     * @returns {?String}
     */
    get ebook () {
        const attribute = this.attributes.find(a => a.id === Attribute.TYPE.PSEUDO_EBOOK)
        return (attribute?.value && attribute.value[0] && attribute.value[0].raw) ?
            attribute.value[0].raw : this.preview.ebook;
    }

    /**
     * @returns {?Object}
     */
    get attachmentId () {
        const attribute = this.attributes.find(a => a.id === Attribute.TYPE.PSEUDO_PREVIEW)
        return (attribute?.value && attribute.value[0] && attribute.value[0].raw) ?
            attribute.value[0].raw : null;
    }
}

/**
 *
 */
class MediaObject extends FileObject {
    set(data) {
        super.set(data)
        this.favorite = data.Favorite || false
    }
}


/**
 *
 */
class EntityObject extends IObject {
}

/**
 *
 */
class CRMObject extends IObject {
}

/**
 * @param {Object[]} attributes
 * @returns {ObjectAttribute[]}
 */
const setAttributes = function(attributes) {
    return Object.values(attributes).map(a => new ObjectAttribute(a))
}

/**
 * @param {Object[]} objects
 * @param {IObject} instance
 * @returns {IObject[]}
 * @todo: может быть имеет смысл передавать весь набор данных?!
 */
const setVariants = function(objects, instance) {
    return objects.map(o => {
        const object = instance.constructor.create(o, instance.attributes)

        extendAttributeCollection(object, Attribute.TYPE.PSEUDO_OBJECT_ID, [{
            raw: object.id,
            title: null
        }])
        extendAttributeCollection(object, Attribute.TYPE.PSEUDO_CREATE_DATE, [{
            raw: o.CreateDate,
            title: o.CreateDate,
            object: { }
        }])
        extendAttributeCollection(object, Attribute.TYPE.PSEUDO_EDIT_DATE, [{
            raw: o.ChangeDate,
            title: o.ChangeDate,
            object: { }
        }])
        extendAttributeCollection(object, Attribute.TYPE.PSEUDO_VARIANT, [{
            raw: o.VariantId,
            title: o.VariantId,
            object: [{ Id: o.VariantId }]
        }])
        extendAttributeCollection(object, Attribute.TYPE.PSEUDO_PREVIEW, [{
            raw: 0,
            title: null,
            object: { Rendered: o.Rendered, Preview: o.Preview }
        }])

        return object
    })
}

const extendAttributeCollection = function(object, attribute, value) {
    const attr = object.attributes.find(a => a.type === attribute)
    if (!attr?.value) {
        if (attr) attr.value = value
        else object.attributes.push(new ObjectAttribute({Id: attribute, Type: attribute, value }))
    }
}

module.exports = {
    IObject,
    FileObject,
    MediaObject,
    EntityObject,
    CRMObject
}