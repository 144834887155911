<template>
    <Dropdown
        ref="dropdown"
        icon="file-arrow-up"
        class="dropdown-menu-upload-profiles"
        :submenus="submenus"
        :active-submenu="activeSubmenu"
        @toggle="activeSubmenu = null; adopted = false"
    >
        <template #toggler="{toggle}">
            <button @click.prevent="toggle" class="dropdown-menu-btn btn-std btn-big btn-icon-only" tabindex="-1"
                v-tooltip="$root.l10n('upload_profile_choose')">
                <IconLight icon="file-arrow-up"/>
            </button>
        </template>
        <template #headline>
            <h3>
                <IconSolid icon="file-arrow-up"/>
                {{ $root.l10n('upload_profile') }}
                <div class="btn-std btn-small btn-icon-only" @click.stop="$refs.dropdown.toggle()"><IconLight icon="times" /></div>
            </h3>
        </template>
        <p>{{ $root.l10n('upload_profile_description') }}</p>
        <SelectInput
            :label="$root.l10n('profile')"
            label-by="Name"
            track-by="Id"
            :options="profiles"
            :errors="error"
            @update:modelValue="adopted = false"
            v-model="profileId"
        >
            <template #status-bar-additional>
                    <span v-if="adopted" class="label-button label-button-dropdown">
                        <IconSolid icon="circle-check" class="color-green"/> {{ $root.l10n('upload_profile_adopted') }}
                    </span>
            </template>
        </SelectInput>
        <div>
            <ButtonStd v-if="profileId === null || profileId === ''" :weight="false"
                class="btn-small no-padding text-blue btn-options"
                @click="activeSubmenu = 'save'">
                <span>{{ $root.l10n('upload_profile_save_as_new') }}</span>
            </ButtonStd>
            <ButtonStd
                v-else-if="!showOptions && $root.user.id === profiles.find(profile => profile.Id === profileId)?.CreatorId"
                :weight="false" class="btn-small no-padding text-blue btn-options"
                @click="showOptions = true">
                <span>{{ $root.l10n('upload_profile_show_options') }}</span>
            </ButtonStd>
            <div class="dropdown-menu-upload-profiles-buttons">
                <button
                    v-if="showOptions && $root.user.id === profiles.find(profile => profile.Id === profileId)?.CreatorId"
                    class="btn-options btn-std btn-small btn-icon-only"
                    type="button" aria-label="edit"
                    v-tooltip="$root.l10n('profile_edit')"
                    @click="activeSubmenu = 'edit'">
                    <IconThin icon="pen-to-square"/>
                </button>
                <button
                    v-if="showOptions && $root.user.id === profiles.find(profile => profile.Id === profileId)?.CreatorId"
                    class="btn-options btn-std btn-small btn-icon-only"
                    type="button" aria-label="delete"
                    v-tooltip="$root.l10n('profile_delete')"
                    @click="activeSubmenu = 'delete'">
                    <IconThin icon="trash"/>
                </button>
            </div>
            <div class="dropdown-menu-upload-profiles-buttons">
                <ButtonStd
                    v-if="!adopted"
                    class="btn-blue btn-medium"
                    :primary="true"
                    :disabled="profileId === null || profileId === ''"
                    @click="$emit('apply', JSON.parse(profiles.find(profile => profile.Id === profileId)?.Attributes)); adopted = true">
                    <span>{{ $root.l10n('adopt_data') }}</span>
                </ButtonStd>
                <ButtonStd
                    v-else
                    class="btn-blue btn-medium"
                    :primary="true"
                    @click="$refs.dropdown.toggle()"
                >
                    <span>{{ $root.l10n('close') }}</span>
                </ButtonStd>
            </div>

        </div>
        <template #subContent-delete>
            <AlertBox v-if="removed" icon="check" :title="$root.l10n('upload_profile_deleted')"
                type="success"></AlertBox>
            <p v-if="!removed">{{ $root.l10n('upload_profile_delete_description', profileName) }}</p>
            <div class="dropdown-menu-upload-profiles-buttons-delete" v-if="!removed">
                <ButtonStd  :weight="false" class="btn-small no-padding text-blue" @click="activeSubmenu = null">
                    <span>{{ $root.l10n('abort') }}</span>
                </ButtonStd>
                <ButtonStd
                    class="btn-blue btn-medium"
                    :primary="true"
                    @click="remove">
                    <span>{{ $root.l10n('delete_profile_yes') }}</span>
                </ButtonStd>
            </div>
            <div v-else class="dropdown-menu-upload-profiles-buttons">
                <ButtonStd
                    class="btn-blue btn-medium"
                    :primary="true"
                    @click="$refs.dropdown.toggle()"
                >
                    <span>{{ $root.l10n('close') }}</span>
                </ButtonStd>
            </div>
        </template>
        <template #subContent-edit>
            <p>{{ $root.l10n('upload_profile_edit_description') }}</p>
            <TextInput
                :label="$root.l10n('profile')"
                :errors="error"
                v-model="profileName"
                @update:modelValue="error = ''"
            >
                <template #status-bar-additional>
                    <span v-if="saved" class="label-button label-button-dropdown">
                        <IconSolid icon="circle-check" class="color-green"/> {{ $root.l10n('upload_profile_saved') }}
                    </span>
                </template>
            </TextInput>
            <SwitchInput
                :label="$root.l10n('upload_profile_share')"
                :help-text="$root.l10n('profile_share')"
                v-model="share"
            />
            <SwitchInput
                :label="$root.l10n('overwrite_values')"
                :help-text="$root.l10n('overwrite_values_description')"
                v-model="overwrite"
            />
            <div class="dropdown-menu-upload-profiles-buttons">
                <ButtonStd
                    v-if="!saved"
                    class="btn-blue btn-medium"
                    :primary="true"
                    :disabled="!profileName"
                    @click="save"
                >
                    <span>{{ $root.l10n('save_profile') }}</span>
                </ButtonStd>
                <ButtonStd
                    v-else
                    class="btn-blue btn-medium"
                    :primary="true"
                    @click="$refs.dropdown.toggle()"
                >
                    <span>{{ $root.l10n('close') }}</span>
                </ButtonStd>
            </div>

        </template>
        <template #subContent-save>
            <p>{{ $root.l10n('upload_profile_save_description') }}</p>
            <TextInput
                :label="$root.l10n('profile')"
                :errors="error"
                v-model="profileName"
                @update:modelValue="error = ''"
            >
                <template #status-bar-additional>
                    <span v-if="saved" class="label-button label-button-dropdown">
                        <IconSolid icon="circle-check" class="color-green"/> {{ $root.l10n('upload_profile_saved') }}
                    </span>
                </template>
            </TextInput>
            <SwitchInput
                :label="$root.l10n('upload_profile_share')"
                :help-text="$root.l10n('profile_share')"
                v-model="share"
            />
            <div class="dropdown-menu-upload-profiles-buttons">
                <ButtonStd
                    v-if="!saved"
                    class="btn-blue btn-medium float-right"
                    :primary="true"
                    :disabled="!profileName"
                    @click="save"
                >
                    <span>{{ $root.l10n('save_profile') }}</span>
                </ButtonStd>
                <ButtonStd
                    v-else
                    class="btn-blue btn-medium float-right"
                    :primary="true"
                    @click="$refs.dropdown.toggle()"
                >
                    <span>{{ $root.l10n('close') }}</span>
                </ButtonStd>
            </div>
        </template>
    </Dropdown>
</template>

<script>
import {ref} from 'vue'
import Dropdown from '../utility/Dropdown.vue'
import IconSolid from '../utility/IconSolid.vue'
import SelectInput from '../forms/inputs/SelectInput.vue'
import ButtonStd from '../forms/ButtonStd.vue'
import SwitchInput from '../forms/inputs/SwitchInput.vue'
import IconThin from '../utility/IconThin.vue'
import TextInput from '../forms/inputs/TextInput.vue'
import AlertBox from '../utility/AlertBox.vue'
import IconLight from '../utility/IconLight.vue'
import AttributeTypes from '../../lib/models/attributetypes'

const ajax = require('../../lib/ajax.js')

export default {
    name: "UploadProfileDropdown",
    components: {IconLight, AlertBox, TextInput, IconThin, SwitchInput, ButtonStd, SelectInput, IconSolid, Dropdown},
    props: {
        moduleId: Number,
        object: Object
    },
    setup() {
        return {
            profiles: ref([]),
            profileId: ref(null),
            error: ref(''),
            showOptions: ref(false),
            share: ref(false),
            overwrite: ref(false),
            submenus: ref([
                {id: "edit", header: "upload_profile_edit"},
                {id: "delete", header: "upload_profile_delete"},
                {id: "save", header: "upload_profile_save"}
            ]),
            activeSubmenu: ref(null),
            profileName: ref(''),
            saved: ref(false),
            adopted: ref(false),
            removed: ref(false)
        }
    },
    computed: {
        attributes() {
            if (!this.object) return []
            const attributes = this.object.attributes
            return attributes.reduce((acc, curr) => {
                let val = structuredClone(curr.value)
                if (AttributeTypes.isModuleLink(curr.attribute.type)) {
                    if (AttributeTypes.isMultipleModuleLink(curr.attribute.type))
                    {
                        Object.keys(curr.value).forEach(key => {
                            if (val[key].object) val[key].object = []
                        })
                    }
                    else {
                        Object.keys(curr.value).forEach(key => {
                            if (val[key].object) val[key].object = {}
                        })
                    }
                }
                acc[curr.attribute.id] = val
                return acc
            }, {})
        }
    },
    methods: {
        init() {
            const routePath =
                this.$root.getRoutePath('uploadProfile.survey', this.moduleId)
            ajax.json(routePath).then(({message, response}) => {
                this.profiles = response
                this.profiles.forEach((profile) => profile.Shared = !!profile.Shared)
            }).catch(e => this.error = e.message || e)
        },
        remove() {
            const routePath = this.$root.getRoutePath('uploadProfile.remove', this.profileId)
            ajax.json(routePath).then(() => {
                this.removed = true
                this.profileId = null
                this.init()
            }).catch(e => this.error = e.message || e)
        },
        save() {
            const routePath = this.profileId ?
                this.$root.getRoutePath('uploadProfile.update', this.profileId) :
                this.$root.getRoutePath('uploadProfile.insert')
            let data = {
                name: this.profileName,
                shared: this.share,
                moduleId: this.moduleId,
                attributes: !this.profileId || this.overwrite ? this.attributes : {}
            }
            ajax.json(routePath, {data: data}).then(() => {
                this.saved = true
                this.init()
            }).catch(e => this.error = e.message || e)
        },
        detectChange() {
            if (this.saved && this.activeSubmenu === 'edit') this.saved = false
        }
    },
    watch: {
        profileId(id) {
            if (id === null || id === '') this.showOptions = false
        },
        activeSubmenu(menu) {
            this.saved = false
            this.removed = false
            if (menu === 'delete') this.profileName = this.profiles.find(profile => profile.Id === this.profileId)?.Name
            if (menu === 'edit') {
                this.profileName = this.profiles.find(profile => profile.Id === this.profileId)?.Name
                this.share = this.profiles.find(profile => profile.Id === this.profileId)?.Shared
            }
            if (menu === 'save') {
                this.profileName = ''
                this.share = false
            }
        },
        share() {
            this.detectChange()
        },
        profileName() {
            this.detectChange()
        }
    },
    created() {
        this.submenus.forEach(s => s.header = this.$root.l10n(s.header))
        this.init()
    }
}
</script>

<style>

.dropdown-menu-container.dropdown-menu-upload-profiles .dropdown-menu-list .dropdown-menu-list-content {
    max-width: 370px;
    width: 370px;
}

.dropdown-menu-container.dropdown-menu-upload-profiles .dropdown-menu-list .i {
    width: 20px;
}

.dropdown-menu-container .btn-big + .dropdown-menu-list {
    top: 50px;
}

.dropdown-menu-upload-profiles-buttons {
    display: flex;
}

.dropdown-menu-upload-profiles-buttons button:first-child {
    margin-left: auto;
}

.dropdown-menu-container.dropdown-menu-upload-profiles .form-element {
    padding: 0;
    margin-bottom: 5px;
}

.btn-options {
    margin-bottom: 16px;
}

.btn-options .i {
    font-size: 14px;
}

.dropdown-menu-upload-profiles-buttons-delete {
    height: 30px;
    display: flex;
}

.dropdown-menu-upload-profiles-buttons-delete button {
    margin-top: auto;
    margin-bottom: auto;
}

.dropdown-menu-upload-profiles-buttons-delete button:last-child {
    margin-left: auto;
}

.label-button-dropdown {
    color: var(--alert_green_100);
    font-size: 12px !important;
    margin-top: 1px;
    text-transform: none;
}

.dropdown-menu-container.dropdown-menu-upload-profiles .alert-box {
    background-color: transparent;
    margin-top: 10px;
}

.dropdown-menu-container.dropdown-menu-upload-profiles .alert-box .alert-box-right{
    padding: 2px 10px 2px 15px;
}

.dropdown-menu-container.dropdown-menu-upload-profiles .alert-box .alert-box-left{
    min-width: 22px;
    width: 22px;
}

.dropdown-menu-container.dropdown-menu-upload-profiles .alert-box .alert-box-left .i{
    height:12px;
}

.dropdown-menu-container.dropdown-menu-upload-profiles .alert-box .alert-box-left:before {
    top: 70%;
    right: -5px;
    width: 10px;
    height: 10px;
    margin-top: -10px;
}

.dropdown-menu-container.dropdown-menu-upload-profiles .dropdown-menu-list.dropdown-menu-top-right .triangle {
    right: 19px;
}
</style>