<template>
    <div id="headerWrapper" class="d-flex flex-row">
        <div id="logo" class="logo-logged" @click.stop="gotoMain"></div>
        <div
            class="nav-toggle"
            :class="navToggled ? 'toggle' : null"
            @click="toggleNav"
        >
            <div></div>
        </div>
        <SearchBar/>
        <Toolbar />
    </div>
</template>

<script>
import SearchBar from "./search/SearchBar.vue";
import Toolbar from "./Toolbar.vue";

export default {
    name: "Header",
    components: { SearchBar, Toolbar },
    data() {
      return {
          navToggled: false,
      }
    },
    props: {
        home: String,
    },
    methods: {
        gotoMain() {
            const route = this.$root.getRoute(this.home || 'home')
            this.$root.goToRoute(route)
        },
        toggleNav() {
            this.navToggled = !this.navToggled;
            this.$emit('toggle-sidebar');
        }
    }
}
</script>

<style lang="scss">
#headerWrapper {
    .nav-toggle {
        margin-right: auto;
        width: 60px;
        height: 60px;
        color: var(--white);
        text-align: center;
        padding: 6px 0;
        cursor: pointer;
        background-color: var(--branding_100);
        z-index: 110;

        &:after,
        &:before,
        div {
            width: 60%;
            background-color: #fff;
            border-radius: 1px;
            content: '';
            display: block;
            height: 3px;
            margin: 9px 0 9px 20%;
            transition: all .3s ease-in-out;
        }

        &.toggle:before {
            transform: translateY(12px) rotate(135deg);
        }

        &.toggle:after {
            transform: translateY(-12px) rotate(-135deg);
        }

        &.toggle div {
            transform: scale(0);
        }

        .i {
            display: inline;
            font-size: 30px;
            line-height: 60px;
        }

        .fa-bars {
            display: none;
        }

        @media screen and (min-width: 1201px) {
            display: none;
        }
    }
}
</style>