
const defaultOptions = {
    handle: null,
    item: null,
    targetDropClass: 'dropped',
    dragoverClass: 'dragover',
    dragoverParent: 'flex-table-row',
    animationDelay: 310
}

/** @param {?Element} */
let draggingTarget

/**
 * @param {Element} el
 */
function addEventListener(el) {
    el.addEventListener('mousedown', invoke)
    el.addEventListener('dragstart', dragstart)
    el.addEventListener('dragend', dragend)
    el.addEventListener('dragenter', dragenter)
    el.addEventListener('dragleave', dragleave)
}

/**
 * @param {Element} el
 * @param el
 */
function addEventDropListener(el) {
    el.addEventListener('drop', drop)
    el.addEventListener("dragover", dragover)
}

/**
 * @param {Element} el
 */
function removeListeners(el) {
    el.removeEventListener('mousedown', invoke)
    el.removeEventListener('dragstart', dragstart)
    el.removeEventListener('dragend', dragend)
    el.removeEventListener('dragenter', dragenter)
    el.removeEventListener('dragleave', dragleave)
}

/**
 * @param {Element} el
 * @param el
 */
function removeEventDropListener(el) {
    el.removeEventListener('drop', drop)
    el.removeEventListener("dragover", dragover)
}


/**
 * @param {DragEvent} event
 */
function dragstart(event) {
    draggingTarget = event.currentTarget || event.target

    setTimeout(() => {
        if (draggingTarget) draggingTarget.classList.add("grabbing")
    }, 50)

    window.getSelection()?.removeAllRanges();

    //dragging = true
    draggingTarget.classList.add('dragged-ori')
    const draggingClone = draggingTarget.cloneNode(true)
    draggingClone.style.backgroundColor = 'var(--alert_blue_50)'
    draggingClone.style.position = 'absolute'
    draggingClone.style.left = '-200vw'
    draggingClone.style.width = draggingTarget.offsetWidth + 'px'

    draggingTarget.parentElement.appendChild(draggingClone)

    event.dataTransfer.effectAllowed = "move"
    event.dataTransfer.setDragImage(draggingClone, 30, 30)
    setTimeout(() => draggingClone.parentElement.removeChild(draggingClone), 0)
    event.stopPropagation()
    //console.log('dragstart', event)
}

/**
 * @param {DragEvent} event
 */
function dragend(event) {
    //console.log('dragend', event)
    this.setAttribute('draggable', false)
    event.target.classList.remove('grabbing')
    releaseDragging()
}

/**
 * @param {DragEvent} event
 */
function dragenter(event) {
    if (draggingTarget !== this) this.classList.add('dragover')
}

/**
 * @param {DragEvent} event
 */
function dragleave(event) {
    if(event.currentTarget !== event.target) return false
    this.classList?.remove('dragover')
}

/**
 * prevents browser behaviour, allows drop event
 * @param {DragEvent} event
 */
function dragover(event) { event.preventDefault() }

/**
 * @param {DragEvent} event
 */
function drop(event) {
    //event.stopPropagation()
    if (this !== draggingTarget) {
        const {target, options} = storage.find(it => it.target === draggingTarget) || {}

        if (!target) {
            console.warn('Oops, dragging element does not exists anymore', draggingTarget)
            return ;
        }

        const dropBoxRect = event.currentTarget.getBoundingClientRect()
        const insertAfterXY = [
            event.clientX - dropBoxRect.x - dropBoxRect.width / 2 > 0,
            event.clientY - dropBoxRect.y - dropBoxRect.height / 2 > 0
        ]

        target.classList.add(options.targetDropClass)
        target.classList.add('animate-slide-left-to-right')
        event.currentTarget.classList.remove('dragover')

        setTimeout(() => {
            target.classList.remove(options.targetDropClass, 'animate-slide-left-to-right')
            releaseDragging()
        }, options.animationDelay || 310)

        const dropEv = new CustomEvent('dropped', {detail: { dragging: target, item: options.item, insertAfterXY }})
        event.currentTarget.dispatchEvent(dropEv)
    } else console.log('dropped at same place');
}

/**
 * @param {MouseEvent} event
 */
function invoke(event) {
    const {target, options} = storage.find(it => it.target === this) || {}
    const handler = options.handle ? Array.from(target.querySelectorAll(options.handle)) : []

    if (options.handle && !handler.length) {
        event.stopPropagation()
        event.preventDefault()
        return false
    } else if (!handler.length) handler.push(target)

    if (handler.some(it => it === event.target || it.contains(event.target))) {
        target.setAttribute('draggable', options.draggable)
        if (options.draggable) event.stopPropagation()
        return true
    } else return false
}

/**
 *
 */
function releaseDragging() {
    draggingTarget?.classList.remove('dragged-ori')
    draggingTarget = null
}

/**
 *
 * @param {{value: boolean|object}} binding
 * @returns {defaultOptions}
 */
function bindingValue2Options(binding) {
    const options = typeof binding.value === 'object' ? binding.value : {draggable: binding.value}
    return Object.assign({}, defaultOptions, options)
}

/**
 * @type Array<{target: Element, vnode: Object, options: defaultOptions}>
 */
const storage = []

/**
 * @export
 * @see https://vuejs.org/guide/reusability/custom-directives.html
 */
export default {
    /**
     * @param {Element} el
     * @param {{value: any, modifiers: object}} binding
     * @param {VNode} vnode
     */
    mounted(el, binding, vnode)  {
        if (binding.modifiers?.drop) {
            addEventDropListener(el)

        } else if (binding.modifiers?.target) {
            storage.push({target: el, options: bindingValue2Options(binding), vnode})
            addEventListener(el)
        } else {
            storage.push({target: el, options: bindingValue2Options(binding), vnode})
            addEventListener(el)
            addEventDropListener(el)
        }
    },

    /**
     * @param {Element} el
     * @param {{value: any}} binding
     * @param {VNode} vnode
     * @param {VNode} prevVnode
     */
    updated(el, binding, vnode, prevVnode) {
        const dat = storage.find(it => it.target === el)
        if (dat && binding.value !== binding.oldValue) {
            //console.log('updated', el, binding, vnode, prevVnode)
            dat.options = bindingValue2Options(binding)
        }
    },

    /**
     * @param {Element} el
     * @param {{value: any}} binding
     * @param {VNode} vnode
     */
    unmounted(el, binding, vnode)  {
        const ix = storage.findIndex(it => it.target === el)
        if (ix >= 0) {
            const dat = storage.splice(ix, 1)[0]
            removeListeners(dat.target)
            removeEventDropListener(el)
            //console.log('unmounted', el, binding, vnode, prevVnode)
        }
    }
}

/**
 * @param {array} list
 * @param {number} targetIx
 * @param {number} placedIx
 * @returns {boolean}
 */
export function resortListByIndexes(list, targetIx, placedIx = -1) {
    if (targetIx > -1 && placedIx > -1) {
        list.splice(placedIx, 0, list.splice(targetIx, 1)[0])
        return true
    } else if (targetIx > -1) {
        list.push(list.splice(targetIx, 1)[0])
        return true
    } else return false
}

/**
 * @param {array} list
 * @param {any} target
 * @param {any} placed
 * @param {function} findItem
 * @returns {boolean}
 */
export function resortList(list, target, placed, findItem = (it, that) => it === that) {
    let c = list.findIndex(it => findItem(it, target))
    let n = list.findIndex(it => findItem(it, placed))

    if(n > c) n--

    return resortListByIndexes(list, c, n)
}