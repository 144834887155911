<template>
    <div class="form-section-container">
        <div class="form-section-title" v-if="typeof label !== 'undefined'">
            {{ label }}
            <div v-if="buttonOnTopShown" class="float-right"><slot name="buttonsOnTop" /></div>
        </div>
        <div class="form-section-content d-flex">
            <div class="form-section-elements">
                <slot/>
            </div>
            <div v-if="$slots.info"
                class="form-section-info"
                :class="hideInformation ? 'hide' : null"
            >
                <div @click="hideInformation = !hideInformation" class="form-section-info-headline cursor-pointer">
                    <span>
                        {{ this.$root.l10n('information') }}
                    </span>
                    <div
                        class="toggle-btn"
                        v-tooltip="hideInformation ? this.$root.l10n('show_information') : this.$root.l10n('hide_information')"
                    >
                        <IconLight icon="chevron-double-right"/>
                    </div>
                </div>
                <div class="form-section-info-content">
                    <slot name="info"/>
                </div>
            </div>
        </div>
        <div class="form-section-buttons" v-if="$slots.buttons">
            <slot name="buttons"/>
        </div>
    </div>
</template>

<script>
import IconLight from "@/components/utility/IconLight";

export default {
    name: "FormSection",
    components: {IconLight},
    props: {
        label: String
    },
    data() {
        return {
            buttonOnTopShown: false,
            hideInformation: true
        }
    },
    mounted(){
        this.buttonOnTopShown = !!this.$slots['buttonsOnTop']
    }
}
</script>

<style lang="scss">
.form-section-container {
    width: 100%;
    margin-bottom: 40px;

    .form-section-container {
        padding: 0;
        margin-bottom: 0;
    }

    .form-section-content {
        .form-section-elements {
            flex: 1 0 0px;
            min-width: 0;
        }
    }

    .form-section-info {
        position: relative;
        flex: 0 0 25%;
        padding: 0 0 0 20px;
        margin-left: 20px;
        font-size: 1rem;
        color: var(--grey_80);
        font-weight: 400;
        line-height: 1.2rem;
        border-left: thin solid var(--grey_15);

        .toggle-btn {
            position: absolute;
            top: 0;
            right: 3px;
            cursor: pointer;
            transition: transform 0.15s;
        }

        .toggle-btn:hover {
            color: var(--color_highlight);
        }

        .form-section-info-headline {
            position: relative;
            font-size: 1rem;
            color: var(--grey_80);
            font-weight: 600;
            text-transform: uppercase;
            padding-right: 20px;
            line-height: 24px;
            margin-bottom: 1rem;
        }

        &.hide {
            max-width: 30px;
        }

        &.hide .toggle-btn {
            transform: rotate(180deg);
        }

        &.hide .form-section-info-content {
            display: none;
        }

        h3 {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            font-weight: 600;
            color: var(--grey_60);
        }

        h3 .alert {
            width: 8px;
            height: 8px;
            margin-right: 5px
        }

        p {
            font-weight: 200;
            margin-bottom: 20px;
        }

        li:before {
            content: '';
            display: inline-block;
            height: 10px;
            width: 10px;
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: -2px;
            margin-right: 4px;
        }
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 1000px) {
    .form-section-container {
        .form-section-content {
            display: block;
            width: 100%;
        }

        .form-section-info {
            width: 100%;
            min-width: 100%;
            padding: 40px 0 0 0;
            margin-top: 40px;
            margin-left: 0;
            border-top: thin solid var(--grey_40);
            border-left: none;

            .toggle-btn {
                transform: rotate(90deg);
            }

            &.hide .toggle-btn {
                transform: rotate(0deg);
            }
        }
    }
}

@media only screen and (min-width: 1001px) {
    .form-section-container .form-section-info .toggle-btn {
        right: 7px;
    }

    .form-section-container .form-section-info.hide {
        padding: 0;

        .form-section-info-headline {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            height: 100%;
            padding-right: 0;
            padding-top: 80px;

            & > span {
                display: block;
                transform: rotate(-90deg);
            }
        }
    }
}
</style>