import {junction, equality, sequence, modifier} from '../../components/layout/header/search/SearchOption'

const SearchTermType = {
    TEXT: 'T',
    SELECT: 'S',
    SWITCH: 'B',
    RANGE: 'R'
}

export default {
    searchTermType: SearchTermType,
    data() {
        return {
            settings: {},
            inputText: '',
        }
    },
    computed: {
        /**
         * @returns {{junction?: [], equality?: [], sequence?: []}}
         */
        basic () {
            return {}
        },
        /**
         * @returns {Boolean}
         */
        emptyValue() {
            return this.inputText instanceof Array ? this.inputText.filter(v => !!v).length === 0 : this.inputText === ''
        }
    },
    methods: {
        /**
         * @returns {{j: string, eq: string, seq: string, m: string}}
         */
        getSettings() {
            const j =  (this.settings.j !== junction.or || (this.basic?.junction?.length === 1 && this.emptyValue)) ? '' : '|'
            const eq = this.settings.eq !== equality.not ? this.settings.eq !== equality.strict ? '' : '=' : '~'
            const seq = this.settings.seq !== sequence.exact ? this.settings.seq !== sequence.bounded ? '*' : '' : '"'
            const cc = (this.settings.m || '').indexOf(modifier.case) === -1 ? '!' : ''
            const di = (this.settings.m || '').indexOf(modifier.diacritical) === -1 ? '@' : ''
            const ex = (this.settings.m || '').indexOf(modifier.extended) !== -1 ? '#' : ''
            return {j, eq, seq, m: cc + di + ex}
        },
        /**
         * @param {string} value
         * @param {{j: string, eq: string, seq: string, m: string}} s
         * @returns {string}
         */
        getSearchValue(value, s) {
            return s.m + s.eq + s.j + s.seq + value + s.seq
        },
        /**
         * @param {string} value
         * @return {{settings: object, value: string}}
         * @note The value should be a single-line string, otherwise the 'm' modifier must be used.
         */
        parseSearchValue(value) {
            const settings = {}

            const match = value.match(/^([!@#]*)*([=~])?(\|)?([*"])?(.*)\4$/m)
            settings.m = this.parseModifiers(match[1] || '')
            settings.j = this.parseJunction(match[3] || '')
            settings.eq = this.parseEquality(match[2] || '')
            settings.seq = this.parseSequence(match[4] || '')
            value = match[5]
            if (match[4] === '"') {
                // hook: detect word groups, as long an unescaped quotes exists in text
                for (let i = 1; i < value.length; i++) {
                    if (value[i] === '"' && value[i - 1] !== '\\') {
                        value = '"' + value.replace(/^"*(.+?)"*$/, '$1') + '"';
                        settings.seq = sequence.bounded
                        break;
                    }
                }
            }

            return {settings, value}
        },
        /**
         * @param {string} junc
         * @returns {string} some of junction
         */
        parseJunction(junc){
            return junc !== '|' ? junction.and : junction.or
        },
        /**
         * @param {string} eq
         * @returns {string} some of equality
         */
        parseEquality(eq){
            return eq !== '=' ? eq !== '~' ? equality.flex : equality.not : equality.strict
        },
        /**
         * @param {string} mod
         * @returns {string} some of equality
         */
        parseModifiers(mod){
            return (mod.indexOf('!') === -1 ? modifier.case : '') + (mod.indexOf('@') === -1 ? modifier.diacritical : '')
                + (mod.indexOf('#') !== -1 ? modifier.extended : '')
        },
        /**
         * @param {string} seq
         * @returns {string} some of sequence
         */
        parseSequence(seq){
            return seq !== '"' ? seq !== '*' ? sequence.bounded : sequence.partial : sequence.exact
        },
        /**
         *
         */
        getSettingsAsTerms () {
            const {j, eq, seq} = this.getSettings()
            const cond = ['search_term_and', 'search_term_flex', 'search_term_bounded']
            if (j) cond[0] = 'search_term_or'

            if (eq === '=') cond[1] = 'search_term_strict'
            else if (eq === '~') cond[1] = 'search_term_not'

            if (seq === '*') cond[2] = 'search_term_partial'
            else if (seq === '"') cond[2] = 'search_term_exact'

            return this.$root.l10n('search_general_option_term', ...cond.map(v => this.$root.l10n(v))) + " "
                + parseModifiers(this.settings.m || '').map(k => this.$root.l10n(k)).join(" ")

        },
        /**
         * @params {string} terms
         * @params {string} title
         * @returns {string}
         */
        parseSearchTerm (terms, title) {
            const pp = preParseSearchTerm(terms)

            const {settings, value} = this.parseSearchValue(pp.terms)
            const type = settings.eq === equality.not ? {type: 'danger', icon: 'times'} : {type: 'success', icon: 'check'}
            const css = settings.eq === equality.not ? 'bg-red-light' : 'bg-green-light'

            const words = value.split('|').filter(v => !!v)
                .map(v => '<span class="badge badge-inline bg-grey-10">' + v + '</span>')

            if (words.length === 0 && settings.eq !== equality.flex) {
                return {
                    type: 'success',
                    icon: 'check',
                    title: title,
                    text: this.$root.l10n(settings.eq === equality.not ? 'search_filled' : 'search_term_empty')
                }
            } else if (words.length === 0) return null

            const cond = [
                // terms\
                [words.splice(0, Math.max(1, words.length-1)).join(', ')].concat(words)
                    .join('&nbsp;<span class="text-underline">' +
                        this.$root.l10n(settings.j === junction.or ? 'search_term_or' : 'search_term_and') + '</span>&nbsp;'),

                //equality
                '<span class="badge badge-inline ' + css + '">' +
                    this.$root.l10n(settings.eq !== equality.not ? settings.eq !== equality.strict ?
                        'search_term_flex' : 'search_term_strict' : 'search_term_not')
                    + '</span>',

                //sequence
                '<span class="badge badge-inline ' + css + '">' +
                    this.$root.l10n(settings.seq !== sequence.exact ? settings.seq !== sequence.bounded ?
                        'search_term_partial' : 'search_term_bounded' : 'search_term_exact')
                    + '</span>'
            ]
            const ll = pp.t === SearchTermType.RANGE ?
                (settings.eq === equality.not ? 'search_range_contain_strict' : 'search_range_contain') :
                (settings.eq === equality.not ? 'search_term_contain_strict' : 'search_term_contain')

            return Object.assign({
                title: title,
                text: this.$root.l10n(ll, ...cond) + ' ' +
                    (pp.t === SearchTermType.TEXT ?
                        parseModifiers(settings.m || '').map(k => this.$root.l10n(k)).join(" ") : '')

            }, type)

        }

    }
}

const parseModifiers = function(m) {
    let terms = []
    if (m.indexOf(modifier.case) === -1)  terms.push('search_term_case_sensitive')
    else terms.push('search_term_case_insensitive')

    if (m.indexOf(modifier.diacritical) === -1) terms.push('search_term_diacritical')
    return terms
}

const preParseSearchTerm = function(terms) {
    let p = terms?.match(/^([A-Z]):(.*)$/)
    if (p) {
        return {t: p[1], terms: p[2]}
    } else return {t: SearchTermType.TEXT, terms: terms || ''}
}

export {
    junction,
    equality,
    sequence,
    modifier
}