<template>
    <div class="tab-nav" :class="overflow ? 'overflow-active' : null">
        <ul ref="tabMenuInlineList" v-resize-observer="handleResize">
            <li v-for="(label, ix) in visibleTabs"
                :key="ix"
                :data-tabindex="ix"
                :class="tab === ix ? 'active' : null"
                ref="navTabs"
            ><button @click.prevent="emitChange(ix)">{{label}}</button></li>
        </ul>

        <Dropdown v-if="overflow">
            <template #toggler="{toggle}">
                <div class="btn-std btn-icon-only dropdown-menu-btn btn-big" @click="() => toggle()">
                    <IconLight icon="ellipsis-h" />
                </div>
            </template>
            <template #list>
                <li v-for="(label, ix) in hiddenTabs"
                    :key="ix"
                    :data-tabindex="ix + visibleTabs.length"
                    :class="tab === ix + visibleTabs ? 'active' : null"
                    ref="navTabs"
                ><button @click.prevent="typeof ix === 'number' ? emitChange(ix + visibleTabs.length) : emitChange(ix)">{{label}}</button></li>
            </template>
        </Dropdown>

    </div>
</template>

<script>
import IconLight from "@/components/utility/IconLight"
import resizeObserver from "@/lib/mixins/resizeObserver"
import Dropdown from "../utility/Dropdown"

export default {
    name: "TabMenu",
    components: {IconLight, Dropdown},
    emits: {click: null},
    props: {
        tabs: [Object, Array],
        tab: [String, Number],
    },
    data() {
        return {
            visibleTabCn: Object.values(this.tabs||{}).length || 0,
            overflow: false
        }
    },
    directives: {
        resizeObserver
    },
    computed: {
        /**
         * @returns {array}
         */
        visibleTabs() {
            if (this.tabs instanceof Array) {
                return this.tabs.slice(0, this.visibleTabCn)
            } else if (this.tabs) {
                return Object.fromEntries(Object.entries(this.tabs).slice(0, this.visibleTabCn))
            } else return []
        },
        /**
         * @returns {array}
         */
        hiddenTabs() {
            if (this.tabs instanceof Array) {
                return this.tabs.slice(this.visibleTabCn)
            } else if (this.tabs) {
                return Object.fromEntries(Object.entries(this.tabs).slice(this.visibleTabCn))
            } else return []
        }
    },
    methods: {
        /** @param {string|number} tab - index key in tabs*/
        emitChange(tab) {
            this.replaceURIHash(tab)
            this.$emit('click', tab)
        },
        /** @param {string} hash */
        replaceURIHash(hash) {
            history.replaceState(history.state, document.title, document.location.pathname + (hash ? '#' + hash : ''))
        },
        /** @param {array|object} tabs */
        checkActiveTab (tabs) {
            let t = document.location.hash ? decodeURI(document.location.hash.substr(1)) : null
            if (t && (t = Object.keys(tabs).indexOf(t)) > -1) {
                this.emitChange(Object.keys(tabs || [])[t] || null)

            } else if (tabs && typeof tabs[this.tab] === 'undefined') {
                if (this.tabs instanceof Array) {
                    this.emitChange(0)
                } else this.emitChange(Object.keys(tabs || [])[0] || null)
            }
        },
        /** */
        handleResize() {
            this.visibleTabCn = this.tabs.length
            this.$nextTick(() => this.computeTabMenuCapacity())
        },
        /** */
        computeTabMenuCapacity() {
            const maxWidth = this.$refs.tabMenuInlineList?.clientWidth
            let sumWidth = 0, counter = 0
            let overflow = false

            if (maxWidth > 0 && this.$refs.navTabs) {
                for (let ix in this.tabs) {
                    const tab = this.$refs.navTabs.find(tab => tab.getAttribute('data-tabindex') === ix)
                    if (!tab) {
                        console.warn('unknown tab index in NavTab', ix)
                        continue
                    }
                    sumWidth += tab.clientWidth
                    if (sumWidth > maxWidth) {
                        overflow = true
                        break
                    } else counter++
                }
            }
            this.overflow = overflow
            this.visibleTabCn = counter
        }
    },
    watch: {
        tabs(tabs) {
            this.checkActiveTab(tabs)
            this.handleResize()
        }
    },
    mounted() {
        this.checkActiveTab(this.tabs)
    },
    beforeUnmount() {
        this.replaceURIHash('')
    }
}
</script>