<template>
    <div id="contentWrapper" class="flex-grow-1">
        <slot name="headline">
            <ContentNaviTabs :propModuleTabsExpanded="moduleTabsExpanded" :list="naviTabs" :active="naviTabActive" @click="$root.goToRoute"/>
        </slot>
        <div id="contentMain" class="d-flex scrollable" ref="contentMain">
            <slot />
        </div>
        <ButtonStd id="scrollTop" class="btn-option float-right" icon-only
           @click="scrollTop"
           v-show="scrollTopBtnShown"
           v-tooltip="{
            content: $root.l10n('to_the_top'),
           }"
        >
            <IconLight icon="chevron-double-up" />
        </ButtonStd>
    </div>
</template>

<script>
import ContentNaviTabs from './ModuleTabs'
import ButtonStd from '../../forms/ButtonStd'
import IconLight from '../../utility/IconLight'
import EventBus from '../../../lib/helpers/EventBus'

export default {
    name: "MainWrapper",
    components: {
        ButtonStd,
        IconLight,
        ContentNaviTabs,
    },
    props:{
        naviTabs: {
            type: [Object, Array],
            default() { return [] }
        },
        naviTabActive: [String,Number],
        viewType: String,
    },
    data() {
        return {
            scrollTopBtnShown: false,
            moduleTabsExpanded: true,
        }
    },
    methods: {
        scrollTop () {
            this.scrollTo(0)
        },
        /** @param {number|((s: number) => number)} to */
        scrollTo(to) {
            if (typeof to === 'function') to = to(this.$refs.contentMain.scrollTop)
            this.$refs.contentMain.scrollTop = to === Infinity ?
                this.$refs.contentMain.scrollHeight : Math.max(0, to)
        },
        scrollHandler() {
            const target = this.$refs.contentMain
            const total = target?.scrollHeight
            const viewed = target?.offsetHeight
            const scroll = target?.scrollTop
            const btmMargin = viewed * 2

            if (total - viewed - scroll <= btmMargin) {
                EventBus.$emit('scroll-on-bottom')
            }
            const endOfScroll = scroll >= total - viewed * 1.5
            EventBus.$emit('end-of-scrolling', endOfScroll)
            this.scrollTopBtnShown = scroll > viewed / 2
            this.moduleTabsExpanded = scroll <= 50
        },
    },
    mounted(){
        this.$refs.contentMain?.addEventListener('scroll', this.scrollHandler)
        EventBus.$on('scroll-trigger', this.scrollHandler)
        EventBus.$on('scroll-top', this.scrollTop)
        EventBus.$on('scroll-to', this.scrollTo)
        window.addEventListener('scroll-top', this.scrollTop)
        this.scrollHandler()
    },
    beforeUnmount(){
        this.$refs.contentMain?.removeEventListener('scroll', this.scrollHandler)
    },
    unmounted(){
        EventBus.$off('scroll-trigger', this.scrollHandler)
        EventBus.$off('scroll-top', this.scrollTop)
        EventBus.$off('scroll-to', this.scrollTo)
        window.removeEventListener('scroll-top', this.scrollTop)
    }
}
</script>