<template>
    <ElementGroup>
        <InputWrapper
            :label="label"
            class="date-picker-wrapper"
            :class="{clearable: clearButtonShown}"
            :label-for="id"
            :helpText="helpText"
            :focused="focused"
            :disabled="disabled"
            :required="required"
            :errors="errors"
            :resettable="isDirty"
            @reset="reset"
            @focus="$refs.datepicker.focus()"
        >
            <date-picker
                value-type="date"
                :value="dpValue"
                :id="id"
                :range="range"
                :name="name"
                :placeholder="placeholder"
                :lang="language"
                :type="type"
                :format="adaptFormat"
                :title-format="adaptFormat"
                :disabled="disabled"
                :clearable="!required && allowEmpty"
                :disabled-date="disabledDays"
                :append-to-body="false"
                :range-separator="rangeSep"
                :default-value="defaultRangeValue"
                v-model:open="open"
                @update:value="onInput"
                @close="focused=false"
                @focus="focused=true"
                @blur="focused=false"
                @open="onCalendarChange"
                @calendar-change="onCalendarChange"
                @pick="onCalendarPick"
                ref="datepicker"
            >
                <template #input="props">
                    <span v-if="period?.value" class="input-span">
                        {{period.title}}
                    </span>
                    <template v-else-if="range">
                        <span :contenteditable="true"
                            class="input-span input-span-min"
                            @focus="focused=true"
                            @keyup="onLowerChanged"
                            @input="onLowerChanged"
                            @blur="onLowerChanged"
                            @keydown="onEnterPress"
                            ref="lower"
                            :data-placeholder="$root.l10n('at_least')"
                        ></span>
                        <span>-</span>
                        <span :contenteditable="true"
                            class="input-span input-span-max"
                            @focus="focused=true"
                            @keyup="onUpperChanged"
                            @input="onUpperChanged"
                            @blur="onUpperChanged"
                            @keydown="onEnterPress"
                            ref="upper"
                            :data-placeholder="$root.l10n('maximum')"
                        ></span>
                        <!-- input v-bind="props" v-on="events" @keyup="keypress" type="hidden" /-->
                    </template>
                    <input v-else v-bind="props" @keyup="keypress" />
                </template>
                <template #header>
                    <div class="mx-datepicker-header-title">
                        <span>{{$root.l10n('select_' + adjustedType)}}</span>
                        <div class="btn-std btn-small btn-icon-only" @click.stop="closePopup()"><IconLight icon="times" /></div>
                    </div>
                    <Multiselect v-if="range && type !== 'time'"
                        class="simple-select"
                        :options="timePeriods"
                        :searchable="false"
                        :show-labels="false"
                        :placeholder="$root.l10n('select_period')"
                        track-by="value"
                        label="title"
                        v-model="period"
                        @update:modelValue="changeTimePeriod"
                    >
                        <template #caret="{ toggle }">
                            <div class="multiselect__icon" @mousedown.prevent.stop="toggle">
                                <span class="multiselect__icon__toggle">
                                    <IconSolid icon="caret-down" class="multiselect__icon__arrow"/>
                                </span>
                            </div>
                        </template>
                    </Multiselect>
                </template>
                <template #icon-clear><span>&nbsp;</span></template>
                <template #icon-calendar><span>&nbsp;</span></template>
            </date-picker>
            <template #additional>
                <button v-if="clearButtonShown" type="button" @click.stop="clearDate" tabindex="-1"><IconLight icon="times"/></button>
                <button v-if="!disabled" type="button" class="btn-calendar" @click.prevent="open = !open" tabindex="-1" v-tooltip="$root.l10n('select_date')"><IconRegular icon="calendar-alt"/></button>
                <slot/>
            </template>
        </InputWrapper>
        <template #additional><slot name="info" /></template>
    </ElementGroup>
</template>

<script>
import {ref, shallowRef} from 'vue'
import ElementGroup from '../ElementGroup'
import InputWrapper from '../InputWrapper'
import DatePicker from 'vue-datepicker-next'
import {languageImport} from './DateFormat.vue'
import { adaptDateFormat, formatDateToDateTime } from '../../../lib/utility'
import DefaultValue from '../../../lib/mixins/defaultValue'
import Multiselect from 'vue-multiselect'
import './multiselect.scss'
const {format, parse, isValidDate} = require('date-format-parse')

import 'vue-datepicker-next/index.css'
import IconSolid from "@/components/utility/IconSolid";
import IconRegular from "@/components/utility/IconRegular";
import IconLight from "@/components/utility/IconLight";

/**
 * @param {Date} date
 * @param {Number} months
 */
const addMonths = function (date, months) {
    const d = date.getDate()
    date.setMonth(date.getMonth() + months)
    //Relative month values are calculated based on the length of months that they pass through
    //set last day of month if greater then current day
    if (date.getDate() !== d) {
        date.setDate(1)
    }
    return date
}

/**
 * @param {number} months in range
 * @returns {[Date, Date]}
 */
const getMonthRange = function (months) {
    const today = new Date()
    const endRange = new Date(today)
    addMonths(endRange, months)
    return rearrangeByTime(today, endRange)
}

/**
 * @param {number} days in range
 * @returns {[Date, Date]}
 */
const getDayRange = function (days) {
    const today = new Date()
    const endRange = new Date(today)
    endRange.setDate(endRange.getDate() + days)
    return rearrangeByTime(today, endRange)
}

/**
 * @param {...Date} range
 * @return {[Date, Date]}
 */
const rearrangeByTime = function (...range) {
    if (range[0] > range[1]) {
        range[0].setHours(23,59,59,999)
        range[1].setHours(23,59,59,1000)
        range = range.reverse()
    } else if (range[0] < range[1]) {
        range[0].setHours(0,0,0,0)
        range[1].setHours(0,0,0,-1)
    } else {
        range[0].setHours(0,0,0,0)
        range[1].setHours(23,59,59,999)
    }
    return range
}
/** */
const getFromTodayRange = function (bound) {
    const today = new Date()
    if (bound > 0) {
        today.setHours(0,0,0,0)
        return [today, null]
    } else {
        today.setHours(23,59,59,999)
        return [null, today]
    }
}

/**
 * @todo: disallow empty value by "!required && allowEmpty"
 */
export default {
    name: "DateInput",
    components: {IconRegular, IconLight, IconSolid, ElementGroup, InputWrapper, Multiselect, DatePicker },
    mixins: [DefaultValue],
    props: {
        id: [String, Number],
        name: String,
        label: String,
        modelValue: [Date, String, Array],
        type: {
            type: String,
            default: 'date',
            validator: function (value) {
                return ['date', 'datetime', 'dateEnd', 'time'].indexOf(value) !== -1
            }
        },
        range: Boolean,
        format: {
            type: String,
            default: "d.m.Y"
        },
        required: Boolean,
        disabled: Boolean,
        allowEmpty: Boolean,
        min: [Date,Number],
        max: [Date,Number],
        errors: [Object,Array,String],
        helpText: String,
        placeholder: String,
    },
    setup() {
        return {
            rangeSep: ' - ',
            period: ref(null),
            language: shallowRef(null)
        }
    },
    data() {
        return {
            focused: false,
            defaultRangeValue: undefined,
            open: false,
            timePeriods: [
                { value: '0' , title: this.$root.l10n('exactly_today'), get: () => getDayRange(0)},
                { value: '-0' , title: this.$root.l10n('before_today'), get: () => getFromTodayRange(-1)},
                { value: '+0' , title: this.$root.l10n('after_today'), get: () => getFromTodayRange(1)},
                { value: '-3 Days' , title: this.$root.l10n('last_3_days'), get: () => getDayRange(-3)},
                { value: '+3 Days' , title: this.$root.l10n('next_3_days'), get: () => getDayRange(+3)},
                { value: '-7 Days' , title: this.$root.l10n('last_7_days'), get: () => getDayRange(-7)},
                { value: '+7 Days' , title: this.$root.l10n('next_7_days'), get: () => getDayRange(+7)},
                { value: '-4 Weeks' , title: this.$root.l10n('last_4_weeks'), get: () => getDayRange(-28)},
                { value: '+4 Weeks' , title: this.$root.l10n('next_4_weeks'), get: () => getDayRange(+28)},
                { value: '-3 Months' , title: this.$root.l10n('last_3_months'), get: () => getMonthRange(-3)},
                { value: '+3 Months' , title: this.$root.l10n('next_3_months'), get: () => getMonthRange(+3)},
                { value: '-1 Year' , title: this.$root.l10n('last_year'), get: () => getMonthRange(-12)},
                { value: '+1 Year' , title: this.$root.l10n('next_year'), get: () => getMonthRange(+12)},
            ]
        }
    },
    computed: {
        adjustedType() {
            return this.type === 'dateEnd' ? 'date' : this.type
        },
        /**
         * @return {Date[]|Date}
         */
        dpValue () {
            if (this.range) {
                let tmp = this.parsePeriodValue(this.modelValue)
                if (tmp) return tmp.get()

                return (this.modelValue ? this.modelValue instanceof Array ? this.modelValue : this.modelValue.split(this.rangeSep) : [])
                    .concat([null,null])?.slice(0,2).map(this.parsePropertyDate)
            } else return this.parsePropertyDate(this.modelValue)
        },
        /**
         * @returns {string}
         */
        adaptFormat() {
            return adaptDateFormat(this.format)
        },
        /**
         * @returns {{ max: ?Date, min: ?Date }}
         */
        calendarBounds() {
            return {
                max: this.parsePropertyDate(this.max),
                min: this.parsePropertyDate(this.min)
            }
        },
        /**
         * @returns {boolean}
         */
        clearButtonShown() {
            return !this.required && !this.disabled && (this.modelValue instanceof Array ? this.modelValue.some(v => !!v) : !!this.modelValue)
        }
    },
    methods: {
        /**
         * @param {Date|Number} value
         * @returns {?Date}
         */
        parsePropertyDate(value) {
            let date
            if (value instanceof Date) {
                date = value
            } else if (value) {
                date = new Date(value)
            } else return null
            return isNaN(date) ? null : date
        },
        /**
         * @param {Date} date
         * @returns {boolean}
         */
        disabledDays(date) {
            const {max, min} = this.calendarBounds
            return !!((max && date > max) || (min && date < min))
        },
        /**
         * @param {Date} date
         * @returns {Date}
         */
        getAllowedDate(date) {
            const {max, min} = this.calendarBounds
            if (max && date > max) return new Date(max.getTime() - 1000)
            else if (min && date < min) return new Date(min.getTime() + 1000)
            else return date
        },
        /**
         * @param {String[]|String||Date} value
         * @returns {Date|Date[]}
         */
        parseValue (value) {
            if (!this.language) return null
            if (this.range) {
                return this.parseRangeValue(value)
            } else if (this.type === 'dateEnd') {
                return this.parseSingleValue(value, [23,59,59])
            } else {
                return this.parseSingleValue(value)
            }
        },
        /**
         * @param {String|Date} value
         * @param {number[]} hours
         * @returns {?Date}
         * @note in case required=true, value must not be empty, unless allowEmpty=true and it was initially empty.
         */
        parseSingleValue (value, hours) {
            if (!this.language) return null

            let date = value ? value instanceof Date ? value : new Date(value) : null
            if (hours !== undefined && date && !isNaN(date)) date.setHours(...hours)

            if (date && this.disabledDays(date)) {
                date = this.getAllowedDate(date)
            } else if (!date && this.required && (this.defaultValue || !this.allowEmpty)) {
                date = this.getAllowedDate(new Date())
            }
            return date
        },
        /**
         * @param {String|String[]|Date[]} value
         * @returns {Date[]}
         */
        parseRangeValue (value) {
            if (!this.language) return []
            const tmp = this.parsePeriodValue(value)
            if (tmp) {
                value = tmp.get()
                this.period = tmp
            }
            value = value ? value instanceof Array ? value : value.split(this.rangeSep) : []
            const date = [].concat(value, ['','']).slice(0,2)
            const time = this.type.indexOf('time') > -1

            if (!date[0]) date[0] = null
            else date[0] = this.parseSingleValue(date[0], time ? undefined : [0,0,0])

            if (!date[1]) date[1] = null
            else date[1] = this.parseSingleValue(date[1], time ? undefined : [23,59,59])

            return date
        },
        /**
         * @param {string|[string]} value
         * @returns {{value: string, title: string, get(): [?Date,?Date]}}
         */
        parsePeriodValue(value) {
            if (value instanceof String) {
                return this.timePeriods.find(o => o.value === value)
            }
            else if (value instanceof Array && value.length === 1) {
                return this.timePeriods.find(o => o.value === value[0])
            } else return null
        },
        /**
         * @param {String|Date|Array} date
         * @return {string}
         */
        formatValue(date) {
            const format = (d) => this.formatDate(d instanceof Date ? d : new Date(d), this.format)
            return date ? date instanceof Array ?
                date.map(v => v && format(v) || '...').join(' - ') : format(date) : ''
        },
        /**
         * @param {String|Date|String[]|Date[]} value
         * @param {Boolean} skip
         */
        onInput(value, skip) {
            const date = this.parseValue(value)
            const output = date instanceof Array ?
                date.map(v => v && formatDateToDateTime(v) || '') : formatDateToDateTime(date)

            this.$emit('update:modelValue', output)
            if (!skip && this.range) {
                this.resetInputSpan()
            }
        },
        /**
         * @param {Event} event
         */
        keypress (event) {
            let date = this.parseDate(event.target.value)//this.$refs.datepicker.formatDate(event.target.value)
            
            if (this.isValidDate(date)) {
                this.onInput(date)
                //this.$refs.datepicker.handleInputChange()
                //this.$refs.datepicker.openPopup()
            }
        },
        /**
         * @param {Event} event
         */
        onEnterPress(event) {
            event.keyCode===13 && event.preventDefault()
        },
        /**
         * @param {Event} event
         */
        onLowerChanged(event) {
            const blur = event.type === 'blur'
            if (blur) event.target.innerText = this.stripSpanInputText(event.target.innerText)
            this.__onLowerUpperChanged([event.target.innerText, this.$refs.upper.innerText], blur)
        },
        /**
         * @param {Event} event
         */
        onUpperChanged(event) {
            const blur = event.type === 'blur'
            if (blur) event.target.innerText = this.stripSpanInputText(event.target.innerText)
            this.__onLowerUpperChanged([this.$refs.lower.innerText, event.target.innerText], blur)
        },
        /**
         * @param {[String,String]} value
         * @param {Boolean} force
         * @notice value item is formatted date string
         */
        __onLowerUpperChanged(value, force) {
            const dates = value.map(v => v && this.parseDate(v) || null)
            value = dates.map(v => v && !isNaN(v) && formatDateToDateTime(v) || '')
            if (value.join(this.rangeSep) === this.modelValue.join(this.rangeSep) && !force) return ;

            this.defaultRangeValue = undefined
            if (value?.some(d => !!d) && value?.some(d => !d)) {
                const date = dates.find(v => v && !isNaN(v))
                this.onInput(value, !force)
                this.$nextTick().then(() => {
                    const range = dates.map(v => v && !isNaN(v) && v || null)
                    if (dates[0]) {
                        dates[1] = new Date(dates[0])
                        dates[1].setMonth(dates[1].getMonth() + 1)
                    } else {
                        dates[0] = new Date(dates[1])
                        dates[0].setMonth(dates[0].getMonth() - 1)
                    }
                    this.defaultRangeValue = date
                    this.$refs.datepicker.currentValue = dates
                    this.$refs.datepicker.userInput = value.join(this.rangeSep)
                    //this.$refs.datepicker.$forceUpdate()
                    this.$nextTick().then(() => this.fixUnboundedRangeCSS(range))
                })

            } else if (
                this.isValidDate(dates)
            ) {
                dates[0].setHours(0,0,0,0)
                dates[1].setHours(23,59,59,59)
                this.onInput(value, !force)
                this.$nextTick().then(() => this.fixUnboundedRangeCSS(dates))
            } else if (value.every(v => !v || isNaN(v)) && force) {
                this.onInput(value, !force)
                this.$nextTick().then(() => this.fixUnboundedRangeCSS([null,null]))
                //notice: force clear-button shown
                this.$refs.datepicker.userInput = ''
            }
        },
        /**
         * @param {{value: string, title: string, get(): [Date, Date]}} option
         */
        changeTimePeriod(option) {
            const range = option?.get() ?? [null,null]
            const value = range.map(v => v ? formatDateToDateTime(v) : '')

            this.$emit('update:modelValue', option?.value ?? [null,null], range)
            this.resetInputSpan()
            this.closePopup()
            this.defaultRangeValue = undefined

            //notice: force clear-button shown
            this.$refs.datepicker.userInput = value.join(this.rangeSep)
        },
        /** */
        onCalendarChange(...args) {
            if (this.range) {
                this.$nextTick().then(() => {
                    const range = this.getRangeDate()
                    if (range.some(d => !!d) && range.some(d => !d))
                        this.fixUnboundedRangeCSS(range)
                })
            }
        },
        /** */
        onCalendarPick(...args) {
            this.period = null
            if (this.range) {
                const range = this.getRangeDate()
                if (range.some(d => !!d) && range.some(d => !d))
                    this.fixUnboundedRangeCSS([null, null])
            }
        },
        /**
         * @returns {[?Date, ?Date]}
         */
        getRangeDate() {
            const range = this.dpValue?.map(d => d || null) || [null,null]
            return range.concat([null,null]).slice(0,2)
        },
        /**
         * in case range till-today or from-today, set/unset in-range class by days in calendar dropdown
         * @param {[?Date, ?Date]} range
         */
        fixUnboundedRangeCSS (range) {
            const panels = this.$refs.datepicker?.querySelectorAll('.mx-calendar-range > .mx-calendar-panel-date')
            const toDateFormat = (date) => [date.getFullYear(), date.getMonth(), date.getDate()].join('/')
            panels.forEach(dom => {
                const days = dom.querySelectorAll('.cell:not(.not-current-month)')
                const month = this.parseDate(days[0]?.getAttribute('title'))
                if (!month) return ;
                days.forEach((el, day) => {
                    month.setDate(day + 1)
                    if (
                        (range[1] && toDateFormat(range[1]) === toDateFormat(month)) ||
                        (range[0] && toDateFormat(range[0]) === toDateFormat(month))
                    ) {
                        el.classList.remove('in-range')
                        el.classList.add('active')
                    } else if (
                        (range[0] || range[1]) &&
                        (!range[1] || (range[1] && range[1] > month)) &&
                        (!range[0] || (range[0] && range[0] < month))
                    ) {
                        el.classList.add('in-range')
                        el.classList.remove('active')
                    } else {
                        el.classList.remove('in-range', 'active')
                    }
                })
            })
        },
        /** */
        resetInputSpan() {
            this.$nextTick().then(() => {
                if (!this.$refs.lower || !this.$refs.upper) return
                this.$refs.lower.innerText = this.dpValue[0] ? this.formatDate(this.dpValue[0]) : ''
                this.$refs.upper.innerText = this.dpValue[1] ? this.formatDate(this.dpValue[1]) : ''
            })
        },
        /** @override defaultValue */
        reset ( ) {
            const def = this.defaultValue
            this.onInput(typeof def === 'object' ? String(def) : def || '')
        },
        /**
         * @param {string} value
         * @returns {string}
         */
        stripSpanInputText (value) {
            const div = document.createElement('div');
            div.innerHTML = value;
            return div.innerText.replace(/\s{2,}/g, ' ')
        },
        clearDate() {
            this.period = null
            if (this.range) {
                this.$emit('update:modelValue', [null,null], [null, null])
                this.resetInputSpan()
            } else this.$emit('update:modelValue', null, null)
            this.$emit('clear')
        },
        isValidDate(value) {
            if (this.range) {
                return isValidRangeDate(value) && value.every((date) => !this.disabledDays(date))
            /*} else if (this.multiple) {
                return isValidDates(value) && value.every((date) => !props.disabledDate(date));*/
            } else return isValidDate(value) && !this.disabledDays(value);            
        },
        formatDate(date, fmt) {
            fmt = fmt || this.adaptFormat
            return format(date, fmt, {locale: this.language?.formatLocale})
        },
        parseDate(value){
            const backupDate = new Date();
            return parse(value, this.adaptFormat, {
                locale: this.language.formatLocale,
                backupDate
            })
        },
        closePopup() {
            this.open = false
        }
    },
    watch: {
        modelValue() {
            if (this.range) this.resetInputSpan()
        }
    },
    created() {
        languageImport(this.$root.systemLocale || 'en') 
            .then(a => this.language = a.default)
            .then(_ => this.onInput(this.modelValue))
            .then(_ => this.setDefaultValue(this.modelValue, true))
            .catch(e => console.error(e))
    }
}

/**
 * @copypasted from vue-datepicker-next
 * @param {[Date,Date]} dates
 * @returns {Boolean}
 */
function isValidRangeDate(dates) { 
    return Array.isArray(dates) && dates.length === 2 && dates.every(isValidDate) && dates[0] <= dates[1]; 
}

</script>

<style lang="scss">

.mx-datepicker {
    width: 100%;
    position: unset;

    .mx-input-wrapper {
        border: none !important;

        .mx-input {
            border: none !important;
            box-shadow: none !important;
            font-family: var(--font-normal);
            font-size: 1rem;
            color: var(--grey_100);
            font-weight: 300;

            &:disabled, &.disabled {
                background-color: var(--grey_5);
                pointer-events: none;
            }
        }

        .mx-icon-calendar, .mx-icon-clear {
            display: none;
        }
    }
}

.mx-datepicker-main {
    font: inherit !important;
    border: none !important;
    font-size: 1rem !important;
    color: var(--grey_100) !important;
    z-index: 9000 !important;
    -webkit-box-shadow: 0 0 4px 0 rgba(221, 221, 221, 1) !important;
    -moz-box-shadow: 0 0 4px 0 rgba(221, 221, 221, 1) !important;
    box-shadow: 0 0 4px 0 rgba(221, 221, 221, 1) !important;
    transition: opacity 0.15s 0s linear;

    &:before {
        position: absolute;
        content: '';
        display: block;
        background: var(--white);
        width: 16px;
        height: 16px;
        right: 22px;
        top: -8px;
        -webkit-box-shadow: 0 0 4px 0 rgba(221, 221, 221, 1);
        -moz-box-shadow: 0 0 4px 0 rgba(221, 221, 221, 1);
        box-shadow: 0 0 4px 0 rgba(221, 221, 221, 1);
        transform: rotate(45deg);
        z-index: -1;
    }

    .mx-datepicker-content {
        padding: 10px;

        &:after, &:before {
            content: '';
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            z-index: 1000;
        }

        /* background dreieck */
        &:after {
            top: 3px;
            right: 20px;
            width: 20px;
            height: 14px;
            background-color: var(--white);
        }

        /* thin border dreieck */
        &:before {
            top: -26px;
            border-color: transparent transparent var(--white) transparent;
            border-width: 16px;
            right: 14px;
            border-style: solid;
        }
    }

    .mx-calendar,
    .mx-date-time,
    .mx-time {
        width: 224px;
        height: 224px;
        padding: 0;
    }
    .mx-date-time-range {
        width: auto;
        height: 224px;
        padding: 0;
    }

    .mx-date-time .mx-time-content,
    .mx-date-time-range .mx-time-content {
        height: 188px;
    }
    .mx-time-content .mx-time-list::after {
        height: 198px;
    }

    .mx-time-header + .mx-time-content .mx-time-list::after {
        height: 162px;
    }


}

.mx-datepicker-popup {
    margin-top: 15px !important;
    left: unset !important;
    right: var(--datepicker-popup-offset-right, -50px) !important;
    top: 100% !important;
}

.clearable .mx-datepicker-popup {
    right: var(--datepicker-popup-offset-right-clearable, -100px) !important;
}

.mx-datepicker-header {
    padding:0;
    border-bottom:none;

    .mx-datepicker-header-title {
        line-height: 1rem;
        margin-bottom: 5px;
        padding: 5px 0 10px 0;
        border-bottom: thin solid var(--grey_15);
    }

    .mx-datepicker-header-title span {
        font-family: var(--font-normal);
        font-size: 1rem;
        color: var(--grey_100);
        font-weight: 600;
    }

    .mx-datepicker-header-title > .btn-std {
        float: right;
    }

    .multiselect {
        .multiselect__tags {
            padding: 0 30px 0 10px;
            min-height: 24px;
            line-height: 24px;
            margin-top: 0;
            top: unset;
        }
        & > .multiselect__icon {
            float: right;
            width: 25px;
            margin: 3px 0 0 0;
        }
    }
}

// datepicker popup

.mx-btn {
    font-size: 1rem !important;
    font-weight: 300 !important;
    color: var(--grey_100) !important;

    &:hover {
        color: var(--color_highlight) !important;
    }
}

.mx-calendar {
    .mx-calendar-content {
        height: auto;
    }

    &.mx-calendar + .mx-calendar {
        margin-left: 20px;
        border: none;
    }

    @media only screen and (max-width: 750px) {
        &.mx-calendar + .mx-calendar {
            margin-left: 0;
        }
    }
}

.mx-time .mx-time-item {
    font-size: 1rem;
    height: 26px;
    line-height: 26px;
    font-weight: 300;

    &.active {
        color: var(--color_highlight);
        font-weight: 400 !important;
    }

    &:hover {
        background-color: transparent;
        color: var(--color_highlight);
    }
}

.mx-calendar-header-label {
    font-size: 1rem !important;
    font-weight: 600 !important;
}

.mx-table {
    table-layout: inherit;
    display: block;
    width: auto;
    font-weight: 300;

    tr {
        display: flex;
        justify-content: space-around;
        width: 100%;
    }

    td, th {
        font-size: 14px;
        line-height: 26px;
        font-weight: 300;
    }

    &.mx-table-date {
        .cell {
            width: 32px;
            height: 26px;

            &.active {
                color: white !important;
                background-color: var(--color_highlight);
                font-weight: 400;
            }

            &:hover:not(.active), &.today:not(.active) {
                color: var(--color_highlight);
            }
        }
    }

    &.mx-table-month, &.mx-table-year {
        .cell {
            height: 26px;
            font-size: 14px;
            width: 26px;
            line-height: 26px;

            &:hover, &.today {
                background-color: transparent;
                color: var(--color_highlight);
            }

            &.active {
                background-color: transparent;
                color: var(--color_highlight);
                font-weight: 400 !important;
            }
        }
    }
}

.mx-icon-left::before,
.mx-icon-right::before,
.mx-icon-double-left::before,
.mx-icon-double-right::before,
.mx-icon-double-left::after,
.mx-icon-double-right::after {
    border-color: var(--grey_60);
}

.mx-datepicker .mx-input-wrapper .mx-icon-calendar,
.multiselect .multiselect__content-wrapper {
    border-color: var(--grey_80);
}

.mx-datepicker-range .mx-table.mx-table-date .cell {
    &:hover:not(.active):not(.in-range):not(.hover-in-range):not(.not-current-month),
    &.hover-in-range:not(.not-current-month),
    &.hover-in-range:not(.not-current-month) + .cell:not(.not-current-month) {
        background-color: #dbedfb !important;
    }
}

@media (max-width: 750px) {
    .mx-datepicker-main .mx-date-time-range {
        height: auto;
    }
    .mx-time+.mx-time {
        border-left: 0;
    }
}


</style>