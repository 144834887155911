<template>
    <div class="popup-header">
        <div v-if="backTitle !== null" class="popup-back-button" @click="$emit('back')">
            <IconLight icon="chevron-left" />
            <span>{{ backTitle !== true ? backTitle : $root.l10n('back') }}</span>
        </div>
        <div class="popup-title">
            <h1 class="popup-title-inner">
                <TextOverflow>{{ headline }}</TextOverflow>
                <span id="popup-header-headline-after" />
            </h1>
        </div>
        <UploadProfileDropdown
            v-if="uploadProfiles"
            :module-id="moduleId"
            :object="object"
            @adoptData="$emit('adoptData', $event)"
        >
        </UploadProfileDropdown>
        <Dropdown
            v-if="languageSwitch"
            :headline="$root.l10n('attribute_language').toLocaleUpperCase()"
            :icon="'globe'"
            :submenus="submenus"
            :active-submenu="activeSubmenu"
            @toggle="activeSubmenu = null"
        >
            <template #toggler="{toggle}">
                <button
                    type="button"
                    class="btn-language btn-std btn-success"
                    @click="toggle"
                    v-tooltip="$root.l10n('attribute_language')"
                >
                    {{locale?.toUpperCase()}}
                    <IconSolid icon="caret-down"/>
                </button>
            </template>
            <template #headline>
                        <h3>
                            <IconSolid icon="globe" />
                            {{ $root.l10n('attribute_language').toUpperCase()}}
                        </h3>
            </template>
            <template #list="{toggle}">
                <li
                    v-for="language in languageSet"
                    :key="language.locale"
                    :class="language.locale === this.locale ? 'active' : null"
                    @click="() => { toggle(); switchLanguage(language.locale); }"
                >
                    <a>{{ language.title }}</a>
                </li>
                <li v-if="languageActions && $root.settings.TRANSLATIONS_ENABLED === 1" class="separated-item" @click="$emit('translate', 'deepl')"><a>{{ $root.l10n('translate_with_deepl') }}</a></li>
                <li v-if="languageActions && $root.settings.TRANSLATIONS_ENABLED === 2" class="separated-item" @click="$emit('translate', 'google')"><a>{{ $root.l10n('translate_with_google') }}</a></li>
                <li v-if="languageActions && $root.settings.TRANSLATIONS_ENABLED === 3" class="separated-item">
                    <button @click.stop="activeSubmenu = 'translate'">
                        <span>{{ $root.l10n('translate') }}</span>
                        <IconThin icon="chevron-right" class="icon-right"/>
                    </button>
                </li>
                <li v-if="languageActions" class="separated-item">
                    <button @click.stop="activeSubmenu = 'transfer'">
                        <span>{{ $root.l10n('transfer_from') }}</span>
                        <IconThin icon="chevron-right" class="icon-right"/>
                    </button>
                </li>
            </template>
            <template #subContent-transfer>
                <ul class="list" @click="$emit('clickMenuItem')">
                    <li v-for="lg in languageSet" :key="lg.locale">
                        <button v-if="lg.locale !== locale" @click="$emit('transfer', lg.locale)">{{ lg.title }}</button>
                    </li>
                </ul>
            </template>
            <template #subContent-translate>
                <ul class="list" @click="$emit('clickMenuItem')">
                    <li>
                        <button @click="$emit('translate', 'deepl')">{{ $root.l10n('translate_with_deepl') }}</button>
                    </li>
                    <li>
                        <button @click="$emit('translate', 'google')">{{ $root.l10n('translate_with_google') }}</button>
                    </li>
                </ul>
            </template>
        </Dropdown>
        <div
            v-if="tabSwitch"
            @click="$emit('toggleLayout')"
            class="btn-std btn-icon-only btn-big tab-mode-button"
            v-tooltip="$root.l10n('change_view')"
        >
            <IconCustom icon="change-view"/>
        </div>
        <div class="btn-std btn-option btn-big btn-icon-only popupClose" @click="$emit('close')" v-tooltip="$root.l10n('close')">
            <ThinLight icon="times" />
        </div>
    </div>
</template>

<script>
import ThinLight from '../../utility/IconThin'
import IconLight from '../../utility/IconLight'
import IconSolid from '../../utility/IconSolid'
import Dropdown from '../../utility/Dropdown.vue'
import TextOverflow from "../../utility/TextOverflow";
import IconCustom from "@/components/utility/IconCustom";
import IconThin from '../../utility/IconThin.vue'
import UploadProfileDropdown from '../../objects/UploadProfileDropdown.vue'

export default {
    name: 'ModalContentHeader',
    components: {
        UploadProfileDropdown,
        IconThin,
        Dropdown,
        IconCustom,
        TextOverflow,
        ThinLight,
        IconLight,
        IconSolid,
    },
    props: {
        headline: String,
        locale: String,
        languageSet: Array,
        backTitle: [String, Boolean],
        tabSwitch: Boolean,
        uploadProfiles: Boolean,
        languageActions: Boolean,
        moduleId: Number,
        object: Object
    },
    data() {
        this.switchLanguage(this.locale)

        return {
            submenus: [
                {id: "translate", header: this.$root.l10n("translate")},
                {id: "transfer", header: this.$root.l10n("transfer_from")}
            ],
            activeSubmenu: null
        }
    },
    computed: {
        languageSwitch () {
            return this.languageSet && this.languageSet.length > 1
        },
    },
    methods: {
        switchLanguage(locale) {
            const language = locale ? this.languageSet?.find(lg => lg.locale === locale) : this.languageSet?.[0]
            if (!language) return ;

            if (language.locale !== this.locale)
                this.$emit('update:locale', language.locale)
        }
    },
    watch: {
        languageSet () {
            this.switchLanguage(this.locale)
        }
    }
}
</script>

<style lang="scss">
.popup-title-inner {
    column-gap: 1rem;
}

#popup-header-headline-after,
.popup-title-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}
</style>